import { default as _91_46_46_46slugs_93Tkh2DwzXzvMeta } from "/app/apps/premium/pages/[...slugs].vue?macro=true";
import { default as _91slug_93qKfaKMvUgmMeta } from "/app/apps/premium/pages/auction/[slug].vue?macro=true";
import { default as _91slug_939DdLR4V9XQMeta } from "/app/apps/premium/pages/auction/current/[slug].vue?macro=true";
import { default as exteriorEHmqmdIgL4Meta } from "/app/apps/premium/pages/beamer/[auctionId]/exterior.vue?macro=true";
import { default as indexgbpXXQ73roMeta } from "/app/apps/premium/pages/beamer/[auctionId]/index.vue?macro=true";
import { default as interiorfbUMgwFnZOMeta } from "/app/apps/premium/pages/beamer/[auctionId]/interior.vue?macro=true";
import { default as pdfX5b69KLndKMeta } from "/app/apps/premium/pages/cms/pdf.vue?macro=true";
import { default as preview_45popupJtWWWqggPJMeta } from "/app/apps/premium/pages/cms/preview-popup.vue?macro=true";
import { default as previewePwT9PCja7Meta } from "/app/apps/premium/pages/cms/preview.vue?macro=true";
import { default as slideY9kgKluY4KMeta } from "/app/apps/premium/pages/cms/slide.vue?macro=true";
import { default as detailsL6oxkYvYFuMeta } from "/app/apps/premium/pages/item/[slug]/details.vue?macro=true";
import { default as indexMvQlE1AIqkMeta } from "/app/apps/premium/pages/item/[slug]/index.vue?macro=true";
import { default as previewS7PgymDCArMeta } from "/app/apps/premium/pages/item/[slug]/preview.vue?macro=true";
import { default as loginVvBJLg8r8VMeta } from "/app/apps/premium/pages/login.vue?macro=true";
export default [
  {
    name: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.name ?? "slugs___en",
    path: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.path ?? "/en/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    alias: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.alias || [],
    redirect: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.redirect,
    component: () => import("/app/apps/premium/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.name ?? "slugs___de___default",
    path: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.path ?? "/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    alias: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.alias || [],
    redirect: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.redirect,
    component: () => import("/app/apps/premium/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.name ?? "slugs___de",
    path: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.path ?? "/de/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    alias: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.alias || [],
    redirect: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.redirect,
    component: () => import("/app/apps/premium/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.name ?? "slugs___pl",
    path: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.path ?? "/pl/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    alias: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.alias || [],
    redirect: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.redirect,
    component: () => import("/app/apps/premium/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.name ?? "slugs___cs",
    path: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.path ?? "/cs/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    alias: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.alias || [],
    redirect: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.redirect,
    component: () => import("/app/apps/premium/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.name ?? "slugs___et",
    path: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.path ?? "/et/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    alias: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.alias || [],
    redirect: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.redirect,
    component: () => import("/app/apps/premium/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.name ?? "slugs___es",
    path: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.path ?? "/es/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    alias: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.alias || [],
    redirect: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.redirect,
    component: () => import("/app/apps/premium/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.name ?? "slugs___fr",
    path: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.path ?? "/fr/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    alias: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.alias || [],
    redirect: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.redirect,
    component: () => import("/app/apps/premium/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.name ?? "slugs___hr",
    path: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.path ?? "/hr/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    alias: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.alias || [],
    redirect: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.redirect,
    component: () => import("/app/apps/premium/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.name ?? "slugs___it",
    path: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.path ?? "/it/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    alias: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.alias || [],
    redirect: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.redirect,
    component: () => import("/app/apps/premium/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.name ?? "slugs___lv",
    path: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.path ?? "/lv/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    alias: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.alias || [],
    redirect: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.redirect,
    component: () => import("/app/apps/premium/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.name ?? "slugs___lt",
    path: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.path ?? "/lt/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    alias: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.alias || [],
    redirect: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.redirect,
    component: () => import("/app/apps/premium/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.name ?? "slugs___hu",
    path: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.path ?? "/hu/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    alias: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.alias || [],
    redirect: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.redirect,
    component: () => import("/app/apps/premium/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.name ?? "slugs___nl",
    path: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.path ?? "/nl/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    alias: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.alias || [],
    redirect: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.redirect,
    component: () => import("/app/apps/premium/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.name ?? "slugs___pt",
    path: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.path ?? "/pt/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    alias: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.alias || [],
    redirect: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.redirect,
    component: () => import("/app/apps/premium/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.name ?? "slugs___ro",
    path: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.path ?? "/ro/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    alias: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.alias || [],
    redirect: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.redirect,
    component: () => import("/app/apps/premium/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.name ?? "slugs___sk",
    path: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.path ?? "/sk/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    alias: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.alias || [],
    redirect: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.redirect,
    component: () => import("/app/apps/premium/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.name ?? "slugs___sl",
    path: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.path ?? "/sl/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    alias: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.alias || [],
    redirect: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.redirect,
    component: () => import("/app/apps/premium/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.name ?? "slugs___sr",
    path: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.path ?? "/sr/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    alias: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.alias || [],
    redirect: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.redirect,
    component: () => import("/app/apps/premium/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.name ?? "slugs___tr",
    path: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.path ?? "/tr/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    alias: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.alias || [],
    redirect: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.redirect,
    component: () => import("/app/apps/premium/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.name ?? "slugs___el",
    path: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.path ?? "/el/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    alias: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.alias || [],
    redirect: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.redirect,
    component: () => import("/app/apps/premium/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.name ?? "slugs___bg",
    path: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.path ?? "/bg/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    alias: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.alias || [],
    redirect: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.redirect,
    component: () => import("/app/apps/premium/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.name ?? "slugs___ru",
    path: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.path ?? "/ru/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    alias: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.alias || [],
    redirect: _91_46_46_46slugs_93Tkh2DwzXzvMeta?.redirect,
    component: () => import("/app/apps/premium/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qKfaKMvUgmMeta?.name ?? "auction-slug___en",
    path: _91slug_93qKfaKMvUgmMeta?.path ?? "/en/auction/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    alias: _91slug_93qKfaKMvUgmMeta?.alias || [],
    redirect: _91slug_93qKfaKMvUgmMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qKfaKMvUgmMeta?.name ?? "auction-slug___de___default",
    path: _91slug_93qKfaKMvUgmMeta?.path ?? "/auktion/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    alias: _91slug_93qKfaKMvUgmMeta?.alias || [],
    redirect: _91slug_93qKfaKMvUgmMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qKfaKMvUgmMeta?.name ?? "auction-slug___de",
    path: _91slug_93qKfaKMvUgmMeta?.path ?? "/de/auktion/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    alias: _91slug_93qKfaKMvUgmMeta?.alias || [],
    redirect: _91slug_93qKfaKMvUgmMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qKfaKMvUgmMeta?.name ?? "auction-slug___pl",
    path: _91slug_93qKfaKMvUgmMeta?.path ?? "/pl/aukcja/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    alias: _91slug_93qKfaKMvUgmMeta?.alias || [],
    redirect: _91slug_93qKfaKMvUgmMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qKfaKMvUgmMeta?.name ?? "auction-slug___cs",
    path: _91slug_93qKfaKMvUgmMeta?.path ?? "/cs/aukce/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    alias: _91slug_93qKfaKMvUgmMeta?.alias || [],
    redirect: _91slug_93qKfaKMvUgmMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qKfaKMvUgmMeta?.name ?? "auction-slug___et",
    path: _91slug_93qKfaKMvUgmMeta?.path ?? "/et/oksjon/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    alias: _91slug_93qKfaKMvUgmMeta?.alias || [],
    redirect: _91slug_93qKfaKMvUgmMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qKfaKMvUgmMeta?.name ?? "auction-slug___es",
    path: _91slug_93qKfaKMvUgmMeta?.path ?? "/es/subasta/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    alias: _91slug_93qKfaKMvUgmMeta?.alias || [],
    redirect: _91slug_93qKfaKMvUgmMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qKfaKMvUgmMeta?.name ?? "auction-slug___fr",
    path: _91slug_93qKfaKMvUgmMeta?.path ?? "/fr/encheres/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    alias: _91slug_93qKfaKMvUgmMeta?.alias || [],
    redirect: _91slug_93qKfaKMvUgmMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qKfaKMvUgmMeta?.name ?? "auction-slug___hr",
    path: _91slug_93qKfaKMvUgmMeta?.path ?? "/hr/aukcija/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    alias: _91slug_93qKfaKMvUgmMeta?.alias || [],
    redirect: _91slug_93qKfaKMvUgmMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qKfaKMvUgmMeta?.name ?? "auction-slug___it",
    path: _91slug_93qKfaKMvUgmMeta?.path ?? "/it/asta/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    alias: _91slug_93qKfaKMvUgmMeta?.alias || [],
    redirect: _91slug_93qKfaKMvUgmMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qKfaKMvUgmMeta?.name ?? "auction-slug___lv",
    path: _91slug_93qKfaKMvUgmMeta?.path ?? "/lv/izsole/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    alias: _91slug_93qKfaKMvUgmMeta?.alias || [],
    redirect: _91slug_93qKfaKMvUgmMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qKfaKMvUgmMeta?.name ?? "auction-slug___lt",
    path: _91slug_93qKfaKMvUgmMeta?.path ?? "/lt/aukcione/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    alias: _91slug_93qKfaKMvUgmMeta?.alias || [],
    redirect: _91slug_93qKfaKMvUgmMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qKfaKMvUgmMeta?.name ?? "auction-slug___hu",
    path: _91slug_93qKfaKMvUgmMeta?.path ?? "/hu/arveres/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    alias: _91slug_93qKfaKMvUgmMeta?.alias || [],
    redirect: _91slug_93qKfaKMvUgmMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qKfaKMvUgmMeta?.name ?? "auction-slug___nl",
    path: _91slug_93qKfaKMvUgmMeta?.path ?? "/nl/veiling/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    alias: _91slug_93qKfaKMvUgmMeta?.alias || [],
    redirect: _91slug_93qKfaKMvUgmMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qKfaKMvUgmMeta?.name ?? "auction-slug___pt",
    path: _91slug_93qKfaKMvUgmMeta?.path ?? "/pt/leilao/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    alias: _91slug_93qKfaKMvUgmMeta?.alias || [],
    redirect: _91slug_93qKfaKMvUgmMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qKfaKMvUgmMeta?.name ?? "auction-slug___ro",
    path: _91slug_93qKfaKMvUgmMeta?.path ?? "/ro/licitatie/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    alias: _91slug_93qKfaKMvUgmMeta?.alias || [],
    redirect: _91slug_93qKfaKMvUgmMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qKfaKMvUgmMeta?.name ?? "auction-slug___sk",
    path: _91slug_93qKfaKMvUgmMeta?.path ?? "/sk/aukcie/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    alias: _91slug_93qKfaKMvUgmMeta?.alias || [],
    redirect: _91slug_93qKfaKMvUgmMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qKfaKMvUgmMeta?.name ?? "auction-slug___sl",
    path: _91slug_93qKfaKMvUgmMeta?.path ?? "/sl/drazba/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    alias: _91slug_93qKfaKMvUgmMeta?.alias || [],
    redirect: _91slug_93qKfaKMvUgmMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qKfaKMvUgmMeta?.name ?? "auction-slug___sr",
    path: _91slug_93qKfaKMvUgmMeta?.path ?? "/sr/aukcija/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    alias: _91slug_93qKfaKMvUgmMeta?.alias || [],
    redirect: _91slug_93qKfaKMvUgmMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qKfaKMvUgmMeta?.name ?? "auction-slug___tr",
    path: _91slug_93qKfaKMvUgmMeta?.path ?? "/tr/acik-arttirma/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    alias: _91slug_93qKfaKMvUgmMeta?.alias || [],
    redirect: _91slug_93qKfaKMvUgmMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qKfaKMvUgmMeta?.name ?? "auction-slug___el",
    path: _91slug_93qKfaKMvUgmMeta?.path ?? "/el/dhmoprasia/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    alias: _91slug_93qKfaKMvUgmMeta?.alias || [],
    redirect: _91slug_93qKfaKMvUgmMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qKfaKMvUgmMeta?.name ?? "auction-slug___bg",
    path: _91slug_93qKfaKMvUgmMeta?.path ?? "/bg/turg/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    alias: _91slug_93qKfaKMvUgmMeta?.alias || [],
    redirect: _91slug_93qKfaKMvUgmMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qKfaKMvUgmMeta?.name ?? "auction-slug___ru",
    path: _91slug_93qKfaKMvUgmMeta?.path ?? "/ru/aukcion/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    alias: _91slug_93qKfaKMvUgmMeta?.alias || [],
    redirect: _91slug_93qKfaKMvUgmMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DdLR4V9XQMeta?.name ?? "auction-current-slug___en",
    path: _91slug_939DdLR4V9XQMeta?.path ?? "/en/auction/current/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    alias: _91slug_939DdLR4V9XQMeta?.alias || [],
    redirect: _91slug_939DdLR4V9XQMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DdLR4V9XQMeta?.name ?? "auction-current-slug___de___default",
    path: _91slug_939DdLR4V9XQMeta?.path ?? "/auktion/aktuell/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    alias: _91slug_939DdLR4V9XQMeta?.alias || [],
    redirect: _91slug_939DdLR4V9XQMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DdLR4V9XQMeta?.name ?? "auction-current-slug___de",
    path: _91slug_939DdLR4V9XQMeta?.path ?? "/de/auktion/aktuell/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    alias: _91slug_939DdLR4V9XQMeta?.alias || [],
    redirect: _91slug_939DdLR4V9XQMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DdLR4V9XQMeta?.name ?? "auction-current-slug___pl",
    path: _91slug_939DdLR4V9XQMeta?.path ?? "/pl/aukcja/aktualna/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    alias: _91slug_939DdLR4V9XQMeta?.alias || [],
    redirect: _91slug_939DdLR4V9XQMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DdLR4V9XQMeta?.name ?? "auction-current-slug___cs",
    path: _91slug_939DdLR4V9XQMeta?.path ?? "/cs/aukce/aktualni/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    alias: _91slug_939DdLR4V9XQMeta?.alias || [],
    redirect: _91slug_939DdLR4V9XQMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DdLR4V9XQMeta?.name ?? "auction-current-slug___et",
    path: _91slug_939DdLR4V9XQMeta?.path ?? "/et/oksjon/praegune/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    alias: _91slug_939DdLR4V9XQMeta?.alias || [],
    redirect: _91slug_939DdLR4V9XQMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DdLR4V9XQMeta?.name ?? "auction-current-slug___es",
    path: _91slug_939DdLR4V9XQMeta?.path ?? "/es/subasta/actual/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    alias: _91slug_939DdLR4V9XQMeta?.alias || [],
    redirect: _91slug_939DdLR4V9XQMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DdLR4V9XQMeta?.name ?? "auction-current-slug___fr",
    path: _91slug_939DdLR4V9XQMeta?.path ?? "/fr/encheres/actuel/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    alias: _91slug_939DdLR4V9XQMeta?.alias || [],
    redirect: _91slug_939DdLR4V9XQMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DdLR4V9XQMeta?.name ?? "auction-current-slug___hr",
    path: _91slug_939DdLR4V9XQMeta?.path ?? "/hr/aukcija/trenutno/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    alias: _91slug_939DdLR4V9XQMeta?.alias || [],
    redirect: _91slug_939DdLR4V9XQMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DdLR4V9XQMeta?.name ?? "auction-current-slug___it",
    path: _91slug_939DdLR4V9XQMeta?.path ?? "/it/asta/attuale/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    alias: _91slug_939DdLR4V9XQMeta?.alias || [],
    redirect: _91slug_939DdLR4V9XQMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DdLR4V9XQMeta?.name ?? "auction-current-slug___lv",
    path: _91slug_939DdLR4V9XQMeta?.path ?? "/lv/izsole/strava/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    alias: _91slug_939DdLR4V9XQMeta?.alias || [],
    redirect: _91slug_939DdLR4V9XQMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DdLR4V9XQMeta?.name ?? "auction-current-slug___lt",
    path: _91slug_939DdLR4V9XQMeta?.path ?? "/lt/aukcione/srove/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    alias: _91slug_939DdLR4V9XQMeta?.alias || [],
    redirect: _91slug_939DdLR4V9XQMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DdLR4V9XQMeta?.name ?? "auction-current-slug___hu",
    path: _91slug_939DdLR4V9XQMeta?.path ?? "/hu/arveres/jelenlegi/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    alias: _91slug_939DdLR4V9XQMeta?.alias || [],
    redirect: _91slug_939DdLR4V9XQMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DdLR4V9XQMeta?.name ?? "auction-current-slug___nl",
    path: _91slug_939DdLR4V9XQMeta?.path ?? "/nl/veiling/huidig/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    alias: _91slug_939DdLR4V9XQMeta?.alias || [],
    redirect: _91slug_939DdLR4V9XQMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DdLR4V9XQMeta?.name ?? "auction-current-slug___pt",
    path: _91slug_939DdLR4V9XQMeta?.path ?? "/pt/leilao/atual/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    alias: _91slug_939DdLR4V9XQMeta?.alias || [],
    redirect: _91slug_939DdLR4V9XQMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DdLR4V9XQMeta?.name ?? "auction-current-slug___ro",
    path: _91slug_939DdLR4V9XQMeta?.path ?? "/ro/licitatie/actual/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    alias: _91slug_939DdLR4V9XQMeta?.alias || [],
    redirect: _91slug_939DdLR4V9XQMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DdLR4V9XQMeta?.name ?? "auction-current-slug___sk",
    path: _91slug_939DdLR4V9XQMeta?.path ?? "/sk/aukcie/prud/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    alias: _91slug_939DdLR4V9XQMeta?.alias || [],
    redirect: _91slug_939DdLR4V9XQMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DdLR4V9XQMeta?.name ?? "auction-current-slug___sl",
    path: _91slug_939DdLR4V9XQMeta?.path ?? "/sl/drazba/trenutno/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    alias: _91slug_939DdLR4V9XQMeta?.alias || [],
    redirect: _91slug_939DdLR4V9XQMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DdLR4V9XQMeta?.name ?? "auction-current-slug___sr",
    path: _91slug_939DdLR4V9XQMeta?.path ?? "/sr/aukcija/trenutni/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    alias: _91slug_939DdLR4V9XQMeta?.alias || [],
    redirect: _91slug_939DdLR4V9XQMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DdLR4V9XQMeta?.name ?? "auction-current-slug___tr",
    path: _91slug_939DdLR4V9XQMeta?.path ?? "/tr/acik-arttirma/akim/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    alias: _91slug_939DdLR4V9XQMeta?.alias || [],
    redirect: _91slug_939DdLR4V9XQMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DdLR4V9XQMeta?.name ?? "auction-current-slug___el",
    path: _91slug_939DdLR4V9XQMeta?.path ?? "/el/dhmoprasia/reyma/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    alias: _91slug_939DdLR4V9XQMeta?.alias || [],
    redirect: _91slug_939DdLR4V9XQMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DdLR4V9XQMeta?.name ?? "auction-current-slug___bg",
    path: _91slug_939DdLR4V9XQMeta?.path ?? "/bg/turg/tekush/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    alias: _91slug_939DdLR4V9XQMeta?.alias || [],
    redirect: _91slug_939DdLR4V9XQMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DdLR4V9XQMeta?.name ?? "auction-current-slug___ru",
    path: _91slug_939DdLR4V9XQMeta?.path ?? "/ru/aukcion/tekushij/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    alias: _91slug_939DdLR4V9XQMeta?.alias || [],
    redirect: _91slug_939DdLR4V9XQMeta?.redirect,
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: exteriorEHmqmdIgL4Meta?.name ?? "beamer-auctionId-exterior___en",
    path: exteriorEHmqmdIgL4Meta?.path ?? "/en/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    alias: exteriorEHmqmdIgL4Meta?.alias || [],
    redirect: exteriorEHmqmdIgL4Meta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue").then(m => m.default || m)
  },
  {
    name: exteriorEHmqmdIgL4Meta?.name ?? "beamer-auctionId-exterior___de___default",
    path: exteriorEHmqmdIgL4Meta?.path ?? "/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    alias: exteriorEHmqmdIgL4Meta?.alias || [],
    redirect: exteriorEHmqmdIgL4Meta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue").then(m => m.default || m)
  },
  {
    name: exteriorEHmqmdIgL4Meta?.name ?? "beamer-auctionId-exterior___de",
    path: exteriorEHmqmdIgL4Meta?.path ?? "/de/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    alias: exteriorEHmqmdIgL4Meta?.alias || [],
    redirect: exteriorEHmqmdIgL4Meta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue").then(m => m.default || m)
  },
  {
    name: exteriorEHmqmdIgL4Meta?.name ?? "beamer-auctionId-exterior___pl",
    path: exteriorEHmqmdIgL4Meta?.path ?? "/pl/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    alias: exteriorEHmqmdIgL4Meta?.alias || [],
    redirect: exteriorEHmqmdIgL4Meta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue").then(m => m.default || m)
  },
  {
    name: exteriorEHmqmdIgL4Meta?.name ?? "beamer-auctionId-exterior___cs",
    path: exteriorEHmqmdIgL4Meta?.path ?? "/cs/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    alias: exteriorEHmqmdIgL4Meta?.alias || [],
    redirect: exteriorEHmqmdIgL4Meta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue").then(m => m.default || m)
  },
  {
    name: exteriorEHmqmdIgL4Meta?.name ?? "beamer-auctionId-exterior___et",
    path: exteriorEHmqmdIgL4Meta?.path ?? "/et/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    alias: exteriorEHmqmdIgL4Meta?.alias || [],
    redirect: exteriorEHmqmdIgL4Meta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue").then(m => m.default || m)
  },
  {
    name: exteriorEHmqmdIgL4Meta?.name ?? "beamer-auctionId-exterior___es",
    path: exteriorEHmqmdIgL4Meta?.path ?? "/es/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    alias: exteriorEHmqmdIgL4Meta?.alias || [],
    redirect: exteriorEHmqmdIgL4Meta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue").then(m => m.default || m)
  },
  {
    name: exteriorEHmqmdIgL4Meta?.name ?? "beamer-auctionId-exterior___fr",
    path: exteriorEHmqmdIgL4Meta?.path ?? "/fr/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    alias: exteriorEHmqmdIgL4Meta?.alias || [],
    redirect: exteriorEHmqmdIgL4Meta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue").then(m => m.default || m)
  },
  {
    name: exteriorEHmqmdIgL4Meta?.name ?? "beamer-auctionId-exterior___hr",
    path: exteriorEHmqmdIgL4Meta?.path ?? "/hr/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    alias: exteriorEHmqmdIgL4Meta?.alias || [],
    redirect: exteriorEHmqmdIgL4Meta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue").then(m => m.default || m)
  },
  {
    name: exteriorEHmqmdIgL4Meta?.name ?? "beamer-auctionId-exterior___it",
    path: exteriorEHmqmdIgL4Meta?.path ?? "/it/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    alias: exteriorEHmqmdIgL4Meta?.alias || [],
    redirect: exteriorEHmqmdIgL4Meta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue").then(m => m.default || m)
  },
  {
    name: exteriorEHmqmdIgL4Meta?.name ?? "beamer-auctionId-exterior___lv",
    path: exteriorEHmqmdIgL4Meta?.path ?? "/lv/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    alias: exteriorEHmqmdIgL4Meta?.alias || [],
    redirect: exteriorEHmqmdIgL4Meta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue").then(m => m.default || m)
  },
  {
    name: exteriorEHmqmdIgL4Meta?.name ?? "beamer-auctionId-exterior___lt",
    path: exteriorEHmqmdIgL4Meta?.path ?? "/lt/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    alias: exteriorEHmqmdIgL4Meta?.alias || [],
    redirect: exteriorEHmqmdIgL4Meta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue").then(m => m.default || m)
  },
  {
    name: exteriorEHmqmdIgL4Meta?.name ?? "beamer-auctionId-exterior___hu",
    path: exteriorEHmqmdIgL4Meta?.path ?? "/hu/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    alias: exteriorEHmqmdIgL4Meta?.alias || [],
    redirect: exteriorEHmqmdIgL4Meta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue").then(m => m.default || m)
  },
  {
    name: exteriorEHmqmdIgL4Meta?.name ?? "beamer-auctionId-exterior___nl",
    path: exteriorEHmqmdIgL4Meta?.path ?? "/nl/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    alias: exteriorEHmqmdIgL4Meta?.alias || [],
    redirect: exteriorEHmqmdIgL4Meta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue").then(m => m.default || m)
  },
  {
    name: exteriorEHmqmdIgL4Meta?.name ?? "beamer-auctionId-exterior___pt",
    path: exteriorEHmqmdIgL4Meta?.path ?? "/pt/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    alias: exteriorEHmqmdIgL4Meta?.alias || [],
    redirect: exteriorEHmqmdIgL4Meta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue").then(m => m.default || m)
  },
  {
    name: exteriorEHmqmdIgL4Meta?.name ?? "beamer-auctionId-exterior___ro",
    path: exteriorEHmqmdIgL4Meta?.path ?? "/ro/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    alias: exteriorEHmqmdIgL4Meta?.alias || [],
    redirect: exteriorEHmqmdIgL4Meta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue").then(m => m.default || m)
  },
  {
    name: exteriorEHmqmdIgL4Meta?.name ?? "beamer-auctionId-exterior___sk",
    path: exteriorEHmqmdIgL4Meta?.path ?? "/sk/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    alias: exteriorEHmqmdIgL4Meta?.alias || [],
    redirect: exteriorEHmqmdIgL4Meta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue").then(m => m.default || m)
  },
  {
    name: exteriorEHmqmdIgL4Meta?.name ?? "beamer-auctionId-exterior___sl",
    path: exteriorEHmqmdIgL4Meta?.path ?? "/sl/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    alias: exteriorEHmqmdIgL4Meta?.alias || [],
    redirect: exteriorEHmqmdIgL4Meta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue").then(m => m.default || m)
  },
  {
    name: exteriorEHmqmdIgL4Meta?.name ?? "beamer-auctionId-exterior___sr",
    path: exteriorEHmqmdIgL4Meta?.path ?? "/sr/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    alias: exteriorEHmqmdIgL4Meta?.alias || [],
    redirect: exteriorEHmqmdIgL4Meta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue").then(m => m.default || m)
  },
  {
    name: exteriorEHmqmdIgL4Meta?.name ?? "beamer-auctionId-exterior___tr",
    path: exteriorEHmqmdIgL4Meta?.path ?? "/tr/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    alias: exteriorEHmqmdIgL4Meta?.alias || [],
    redirect: exteriorEHmqmdIgL4Meta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue").then(m => m.default || m)
  },
  {
    name: exteriorEHmqmdIgL4Meta?.name ?? "beamer-auctionId-exterior___el",
    path: exteriorEHmqmdIgL4Meta?.path ?? "/el/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    alias: exteriorEHmqmdIgL4Meta?.alias || [],
    redirect: exteriorEHmqmdIgL4Meta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue").then(m => m.default || m)
  },
  {
    name: exteriorEHmqmdIgL4Meta?.name ?? "beamer-auctionId-exterior___bg",
    path: exteriorEHmqmdIgL4Meta?.path ?? "/bg/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    alias: exteriorEHmqmdIgL4Meta?.alias || [],
    redirect: exteriorEHmqmdIgL4Meta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue").then(m => m.default || m)
  },
  {
    name: exteriorEHmqmdIgL4Meta?.name ?? "beamer-auctionId-exterior___ru",
    path: exteriorEHmqmdIgL4Meta?.path ?? "/ru/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    alias: exteriorEHmqmdIgL4Meta?.alias || [],
    redirect: exteriorEHmqmdIgL4Meta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue").then(m => m.default || m)
  },
  {
    name: indexgbpXXQ73roMeta?.name ?? "beamer-auctionId___en",
    path: indexgbpXXQ73roMeta?.path ?? "/en/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    alias: indexgbpXXQ73roMeta?.alias || [],
    redirect: indexgbpXXQ73roMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgbpXXQ73roMeta?.name ?? "beamer-auctionId___de___default",
    path: indexgbpXXQ73roMeta?.path ?? "/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    alias: indexgbpXXQ73roMeta?.alias || [],
    redirect: indexgbpXXQ73roMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgbpXXQ73roMeta?.name ?? "beamer-auctionId___de",
    path: indexgbpXXQ73roMeta?.path ?? "/de/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    alias: indexgbpXXQ73roMeta?.alias || [],
    redirect: indexgbpXXQ73roMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgbpXXQ73roMeta?.name ?? "beamer-auctionId___pl",
    path: indexgbpXXQ73roMeta?.path ?? "/pl/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    alias: indexgbpXXQ73roMeta?.alias || [],
    redirect: indexgbpXXQ73roMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgbpXXQ73roMeta?.name ?? "beamer-auctionId___cs",
    path: indexgbpXXQ73roMeta?.path ?? "/cs/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    alias: indexgbpXXQ73roMeta?.alias || [],
    redirect: indexgbpXXQ73roMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgbpXXQ73roMeta?.name ?? "beamer-auctionId___et",
    path: indexgbpXXQ73roMeta?.path ?? "/et/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    alias: indexgbpXXQ73roMeta?.alias || [],
    redirect: indexgbpXXQ73roMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgbpXXQ73roMeta?.name ?? "beamer-auctionId___es",
    path: indexgbpXXQ73roMeta?.path ?? "/es/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    alias: indexgbpXXQ73roMeta?.alias || [],
    redirect: indexgbpXXQ73roMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgbpXXQ73roMeta?.name ?? "beamer-auctionId___fr",
    path: indexgbpXXQ73roMeta?.path ?? "/fr/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    alias: indexgbpXXQ73roMeta?.alias || [],
    redirect: indexgbpXXQ73roMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgbpXXQ73roMeta?.name ?? "beamer-auctionId___hr",
    path: indexgbpXXQ73roMeta?.path ?? "/hr/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    alias: indexgbpXXQ73roMeta?.alias || [],
    redirect: indexgbpXXQ73roMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgbpXXQ73roMeta?.name ?? "beamer-auctionId___it",
    path: indexgbpXXQ73roMeta?.path ?? "/it/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    alias: indexgbpXXQ73roMeta?.alias || [],
    redirect: indexgbpXXQ73roMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgbpXXQ73roMeta?.name ?? "beamer-auctionId___lv",
    path: indexgbpXXQ73roMeta?.path ?? "/lv/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    alias: indexgbpXXQ73roMeta?.alias || [],
    redirect: indexgbpXXQ73roMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgbpXXQ73roMeta?.name ?? "beamer-auctionId___lt",
    path: indexgbpXXQ73roMeta?.path ?? "/lt/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    alias: indexgbpXXQ73roMeta?.alias || [],
    redirect: indexgbpXXQ73roMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgbpXXQ73roMeta?.name ?? "beamer-auctionId___hu",
    path: indexgbpXXQ73roMeta?.path ?? "/hu/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    alias: indexgbpXXQ73roMeta?.alias || [],
    redirect: indexgbpXXQ73roMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgbpXXQ73roMeta?.name ?? "beamer-auctionId___nl",
    path: indexgbpXXQ73roMeta?.path ?? "/nl/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    alias: indexgbpXXQ73roMeta?.alias || [],
    redirect: indexgbpXXQ73roMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgbpXXQ73roMeta?.name ?? "beamer-auctionId___pt",
    path: indexgbpXXQ73roMeta?.path ?? "/pt/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    alias: indexgbpXXQ73roMeta?.alias || [],
    redirect: indexgbpXXQ73roMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgbpXXQ73roMeta?.name ?? "beamer-auctionId___ro",
    path: indexgbpXXQ73roMeta?.path ?? "/ro/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    alias: indexgbpXXQ73roMeta?.alias || [],
    redirect: indexgbpXXQ73roMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgbpXXQ73roMeta?.name ?? "beamer-auctionId___sk",
    path: indexgbpXXQ73roMeta?.path ?? "/sk/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    alias: indexgbpXXQ73roMeta?.alias || [],
    redirect: indexgbpXXQ73roMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgbpXXQ73roMeta?.name ?? "beamer-auctionId___sl",
    path: indexgbpXXQ73roMeta?.path ?? "/sl/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    alias: indexgbpXXQ73roMeta?.alias || [],
    redirect: indexgbpXXQ73roMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgbpXXQ73roMeta?.name ?? "beamer-auctionId___sr",
    path: indexgbpXXQ73roMeta?.path ?? "/sr/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    alias: indexgbpXXQ73roMeta?.alias || [],
    redirect: indexgbpXXQ73roMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgbpXXQ73roMeta?.name ?? "beamer-auctionId___tr",
    path: indexgbpXXQ73roMeta?.path ?? "/tr/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    alias: indexgbpXXQ73roMeta?.alias || [],
    redirect: indexgbpXXQ73roMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgbpXXQ73roMeta?.name ?? "beamer-auctionId___el",
    path: indexgbpXXQ73roMeta?.path ?? "/el/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    alias: indexgbpXXQ73roMeta?.alias || [],
    redirect: indexgbpXXQ73roMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgbpXXQ73roMeta?.name ?? "beamer-auctionId___bg",
    path: indexgbpXXQ73roMeta?.path ?? "/bg/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    alias: indexgbpXXQ73roMeta?.alias || [],
    redirect: indexgbpXXQ73roMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgbpXXQ73roMeta?.name ?? "beamer-auctionId___ru",
    path: indexgbpXXQ73roMeta?.path ?? "/ru/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    alias: indexgbpXXQ73roMeta?.alias || [],
    redirect: indexgbpXXQ73roMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue").then(m => m.default || m)
  },
  {
    name: interiorfbUMgwFnZOMeta?.name ?? "beamer-auctionId-interior___en",
    path: interiorfbUMgwFnZOMeta?.path ?? "/en/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    alias: interiorfbUMgwFnZOMeta?.alias || [],
    redirect: interiorfbUMgwFnZOMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue").then(m => m.default || m)
  },
  {
    name: interiorfbUMgwFnZOMeta?.name ?? "beamer-auctionId-interior___de___default",
    path: interiorfbUMgwFnZOMeta?.path ?? "/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    alias: interiorfbUMgwFnZOMeta?.alias || [],
    redirect: interiorfbUMgwFnZOMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue").then(m => m.default || m)
  },
  {
    name: interiorfbUMgwFnZOMeta?.name ?? "beamer-auctionId-interior___de",
    path: interiorfbUMgwFnZOMeta?.path ?? "/de/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    alias: interiorfbUMgwFnZOMeta?.alias || [],
    redirect: interiorfbUMgwFnZOMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue").then(m => m.default || m)
  },
  {
    name: interiorfbUMgwFnZOMeta?.name ?? "beamer-auctionId-interior___pl",
    path: interiorfbUMgwFnZOMeta?.path ?? "/pl/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    alias: interiorfbUMgwFnZOMeta?.alias || [],
    redirect: interiorfbUMgwFnZOMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue").then(m => m.default || m)
  },
  {
    name: interiorfbUMgwFnZOMeta?.name ?? "beamer-auctionId-interior___cs",
    path: interiorfbUMgwFnZOMeta?.path ?? "/cs/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    alias: interiorfbUMgwFnZOMeta?.alias || [],
    redirect: interiorfbUMgwFnZOMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue").then(m => m.default || m)
  },
  {
    name: interiorfbUMgwFnZOMeta?.name ?? "beamer-auctionId-interior___et",
    path: interiorfbUMgwFnZOMeta?.path ?? "/et/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    alias: interiorfbUMgwFnZOMeta?.alias || [],
    redirect: interiorfbUMgwFnZOMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue").then(m => m.default || m)
  },
  {
    name: interiorfbUMgwFnZOMeta?.name ?? "beamer-auctionId-interior___es",
    path: interiorfbUMgwFnZOMeta?.path ?? "/es/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    alias: interiorfbUMgwFnZOMeta?.alias || [],
    redirect: interiorfbUMgwFnZOMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue").then(m => m.default || m)
  },
  {
    name: interiorfbUMgwFnZOMeta?.name ?? "beamer-auctionId-interior___fr",
    path: interiorfbUMgwFnZOMeta?.path ?? "/fr/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    alias: interiorfbUMgwFnZOMeta?.alias || [],
    redirect: interiorfbUMgwFnZOMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue").then(m => m.default || m)
  },
  {
    name: interiorfbUMgwFnZOMeta?.name ?? "beamer-auctionId-interior___hr",
    path: interiorfbUMgwFnZOMeta?.path ?? "/hr/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    alias: interiorfbUMgwFnZOMeta?.alias || [],
    redirect: interiorfbUMgwFnZOMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue").then(m => m.default || m)
  },
  {
    name: interiorfbUMgwFnZOMeta?.name ?? "beamer-auctionId-interior___it",
    path: interiorfbUMgwFnZOMeta?.path ?? "/it/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    alias: interiorfbUMgwFnZOMeta?.alias || [],
    redirect: interiorfbUMgwFnZOMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue").then(m => m.default || m)
  },
  {
    name: interiorfbUMgwFnZOMeta?.name ?? "beamer-auctionId-interior___lv",
    path: interiorfbUMgwFnZOMeta?.path ?? "/lv/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    alias: interiorfbUMgwFnZOMeta?.alias || [],
    redirect: interiorfbUMgwFnZOMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue").then(m => m.default || m)
  },
  {
    name: interiorfbUMgwFnZOMeta?.name ?? "beamer-auctionId-interior___lt",
    path: interiorfbUMgwFnZOMeta?.path ?? "/lt/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    alias: interiorfbUMgwFnZOMeta?.alias || [],
    redirect: interiorfbUMgwFnZOMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue").then(m => m.default || m)
  },
  {
    name: interiorfbUMgwFnZOMeta?.name ?? "beamer-auctionId-interior___hu",
    path: interiorfbUMgwFnZOMeta?.path ?? "/hu/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    alias: interiorfbUMgwFnZOMeta?.alias || [],
    redirect: interiorfbUMgwFnZOMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue").then(m => m.default || m)
  },
  {
    name: interiorfbUMgwFnZOMeta?.name ?? "beamer-auctionId-interior___nl",
    path: interiorfbUMgwFnZOMeta?.path ?? "/nl/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    alias: interiorfbUMgwFnZOMeta?.alias || [],
    redirect: interiorfbUMgwFnZOMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue").then(m => m.default || m)
  },
  {
    name: interiorfbUMgwFnZOMeta?.name ?? "beamer-auctionId-interior___pt",
    path: interiorfbUMgwFnZOMeta?.path ?? "/pt/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    alias: interiorfbUMgwFnZOMeta?.alias || [],
    redirect: interiorfbUMgwFnZOMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue").then(m => m.default || m)
  },
  {
    name: interiorfbUMgwFnZOMeta?.name ?? "beamer-auctionId-interior___ro",
    path: interiorfbUMgwFnZOMeta?.path ?? "/ro/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    alias: interiorfbUMgwFnZOMeta?.alias || [],
    redirect: interiorfbUMgwFnZOMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue").then(m => m.default || m)
  },
  {
    name: interiorfbUMgwFnZOMeta?.name ?? "beamer-auctionId-interior___sk",
    path: interiorfbUMgwFnZOMeta?.path ?? "/sk/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    alias: interiorfbUMgwFnZOMeta?.alias || [],
    redirect: interiorfbUMgwFnZOMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue").then(m => m.default || m)
  },
  {
    name: interiorfbUMgwFnZOMeta?.name ?? "beamer-auctionId-interior___sl",
    path: interiorfbUMgwFnZOMeta?.path ?? "/sl/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    alias: interiorfbUMgwFnZOMeta?.alias || [],
    redirect: interiorfbUMgwFnZOMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue").then(m => m.default || m)
  },
  {
    name: interiorfbUMgwFnZOMeta?.name ?? "beamer-auctionId-interior___sr",
    path: interiorfbUMgwFnZOMeta?.path ?? "/sr/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    alias: interiorfbUMgwFnZOMeta?.alias || [],
    redirect: interiorfbUMgwFnZOMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue").then(m => m.default || m)
  },
  {
    name: interiorfbUMgwFnZOMeta?.name ?? "beamer-auctionId-interior___tr",
    path: interiorfbUMgwFnZOMeta?.path ?? "/tr/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    alias: interiorfbUMgwFnZOMeta?.alias || [],
    redirect: interiorfbUMgwFnZOMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue").then(m => m.default || m)
  },
  {
    name: interiorfbUMgwFnZOMeta?.name ?? "beamer-auctionId-interior___el",
    path: interiorfbUMgwFnZOMeta?.path ?? "/el/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    alias: interiorfbUMgwFnZOMeta?.alias || [],
    redirect: interiorfbUMgwFnZOMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue").then(m => m.default || m)
  },
  {
    name: interiorfbUMgwFnZOMeta?.name ?? "beamer-auctionId-interior___bg",
    path: interiorfbUMgwFnZOMeta?.path ?? "/bg/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    alias: interiorfbUMgwFnZOMeta?.alias || [],
    redirect: interiorfbUMgwFnZOMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue").then(m => m.default || m)
  },
  {
    name: interiorfbUMgwFnZOMeta?.name ?? "beamer-auctionId-interior___ru",
    path: interiorfbUMgwFnZOMeta?.path ?? "/ru/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    alias: interiorfbUMgwFnZOMeta?.alias || [],
    redirect: interiorfbUMgwFnZOMeta?.redirect,
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue").then(m => m.default || m)
  },
  {
    name: pdfX5b69KLndKMeta?.name ?? "cms-pdf___en",
    path: pdfX5b69KLndKMeta?.path ?? "/en/cms/pdf",
    meta: pdfX5b69KLndKMeta || {},
    alias: pdfX5b69KLndKMeta?.alias || [],
    redirect: pdfX5b69KLndKMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdfX5b69KLndKMeta?.name ?? "cms-pdf___de___default",
    path: pdfX5b69KLndKMeta?.path ?? "/cms/pdf",
    meta: pdfX5b69KLndKMeta || {},
    alias: pdfX5b69KLndKMeta?.alias || [],
    redirect: pdfX5b69KLndKMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdfX5b69KLndKMeta?.name ?? "cms-pdf___de",
    path: pdfX5b69KLndKMeta?.path ?? "/de/cms/pdf",
    meta: pdfX5b69KLndKMeta || {},
    alias: pdfX5b69KLndKMeta?.alias || [],
    redirect: pdfX5b69KLndKMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdfX5b69KLndKMeta?.name ?? "cms-pdf___pl",
    path: pdfX5b69KLndKMeta?.path ?? "/pl/cms/pdf",
    meta: pdfX5b69KLndKMeta || {},
    alias: pdfX5b69KLndKMeta?.alias || [],
    redirect: pdfX5b69KLndKMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdfX5b69KLndKMeta?.name ?? "cms-pdf___cs",
    path: pdfX5b69KLndKMeta?.path ?? "/cs/cms/pdf",
    meta: pdfX5b69KLndKMeta || {},
    alias: pdfX5b69KLndKMeta?.alias || [],
    redirect: pdfX5b69KLndKMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdfX5b69KLndKMeta?.name ?? "cms-pdf___et",
    path: pdfX5b69KLndKMeta?.path ?? "/et/cms/pdf",
    meta: pdfX5b69KLndKMeta || {},
    alias: pdfX5b69KLndKMeta?.alias || [],
    redirect: pdfX5b69KLndKMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdfX5b69KLndKMeta?.name ?? "cms-pdf___es",
    path: pdfX5b69KLndKMeta?.path ?? "/es/cms/pdf",
    meta: pdfX5b69KLndKMeta || {},
    alias: pdfX5b69KLndKMeta?.alias || [],
    redirect: pdfX5b69KLndKMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdfX5b69KLndKMeta?.name ?? "cms-pdf___fr",
    path: pdfX5b69KLndKMeta?.path ?? "/fr/cms/pdf",
    meta: pdfX5b69KLndKMeta || {},
    alias: pdfX5b69KLndKMeta?.alias || [],
    redirect: pdfX5b69KLndKMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdfX5b69KLndKMeta?.name ?? "cms-pdf___hr",
    path: pdfX5b69KLndKMeta?.path ?? "/hr/cms/pdf",
    meta: pdfX5b69KLndKMeta || {},
    alias: pdfX5b69KLndKMeta?.alias || [],
    redirect: pdfX5b69KLndKMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdfX5b69KLndKMeta?.name ?? "cms-pdf___it",
    path: pdfX5b69KLndKMeta?.path ?? "/it/cms/pdf",
    meta: pdfX5b69KLndKMeta || {},
    alias: pdfX5b69KLndKMeta?.alias || [],
    redirect: pdfX5b69KLndKMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdfX5b69KLndKMeta?.name ?? "cms-pdf___lv",
    path: pdfX5b69KLndKMeta?.path ?? "/lv/cms/pdf",
    meta: pdfX5b69KLndKMeta || {},
    alias: pdfX5b69KLndKMeta?.alias || [],
    redirect: pdfX5b69KLndKMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdfX5b69KLndKMeta?.name ?? "cms-pdf___lt",
    path: pdfX5b69KLndKMeta?.path ?? "/lt/cms/pdf",
    meta: pdfX5b69KLndKMeta || {},
    alias: pdfX5b69KLndKMeta?.alias || [],
    redirect: pdfX5b69KLndKMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdfX5b69KLndKMeta?.name ?? "cms-pdf___hu",
    path: pdfX5b69KLndKMeta?.path ?? "/hu/cms/pdf",
    meta: pdfX5b69KLndKMeta || {},
    alias: pdfX5b69KLndKMeta?.alias || [],
    redirect: pdfX5b69KLndKMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdfX5b69KLndKMeta?.name ?? "cms-pdf___nl",
    path: pdfX5b69KLndKMeta?.path ?? "/nl/cms/pdf",
    meta: pdfX5b69KLndKMeta || {},
    alias: pdfX5b69KLndKMeta?.alias || [],
    redirect: pdfX5b69KLndKMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdfX5b69KLndKMeta?.name ?? "cms-pdf___pt",
    path: pdfX5b69KLndKMeta?.path ?? "/pt/cms/pdf",
    meta: pdfX5b69KLndKMeta || {},
    alias: pdfX5b69KLndKMeta?.alias || [],
    redirect: pdfX5b69KLndKMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdfX5b69KLndKMeta?.name ?? "cms-pdf___ro",
    path: pdfX5b69KLndKMeta?.path ?? "/ro/cms/pdf",
    meta: pdfX5b69KLndKMeta || {},
    alias: pdfX5b69KLndKMeta?.alias || [],
    redirect: pdfX5b69KLndKMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdfX5b69KLndKMeta?.name ?? "cms-pdf___sk",
    path: pdfX5b69KLndKMeta?.path ?? "/sk/cms/pdf",
    meta: pdfX5b69KLndKMeta || {},
    alias: pdfX5b69KLndKMeta?.alias || [],
    redirect: pdfX5b69KLndKMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdfX5b69KLndKMeta?.name ?? "cms-pdf___sl",
    path: pdfX5b69KLndKMeta?.path ?? "/sl/cms/pdf",
    meta: pdfX5b69KLndKMeta || {},
    alias: pdfX5b69KLndKMeta?.alias || [],
    redirect: pdfX5b69KLndKMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdfX5b69KLndKMeta?.name ?? "cms-pdf___sr",
    path: pdfX5b69KLndKMeta?.path ?? "/sr/cms/pdf",
    meta: pdfX5b69KLndKMeta || {},
    alias: pdfX5b69KLndKMeta?.alias || [],
    redirect: pdfX5b69KLndKMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdfX5b69KLndKMeta?.name ?? "cms-pdf___tr",
    path: pdfX5b69KLndKMeta?.path ?? "/tr/cms/pdf",
    meta: pdfX5b69KLndKMeta || {},
    alias: pdfX5b69KLndKMeta?.alias || [],
    redirect: pdfX5b69KLndKMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdfX5b69KLndKMeta?.name ?? "cms-pdf___el",
    path: pdfX5b69KLndKMeta?.path ?? "/el/cms/pdf",
    meta: pdfX5b69KLndKMeta || {},
    alias: pdfX5b69KLndKMeta?.alias || [],
    redirect: pdfX5b69KLndKMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdfX5b69KLndKMeta?.name ?? "cms-pdf___bg",
    path: pdfX5b69KLndKMeta?.path ?? "/bg/cms/pdf",
    meta: pdfX5b69KLndKMeta || {},
    alias: pdfX5b69KLndKMeta?.alias || [],
    redirect: pdfX5b69KLndKMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdfX5b69KLndKMeta?.name ?? "cms-pdf___ru",
    path: pdfX5b69KLndKMeta?.path ?? "/ru/cms/pdf",
    meta: pdfX5b69KLndKMeta || {},
    alias: pdfX5b69KLndKMeta?.alias || [],
    redirect: pdfX5b69KLndKMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: preview_45popupJtWWWqggPJMeta?.name ?? "cms-preview-popup___en",
    path: preview_45popupJtWWWqggPJMeta?.path ?? "/en/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    alias: preview_45popupJtWWWqggPJMeta?.alias || [],
    redirect: preview_45popupJtWWWqggPJMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue").then(m => m.default || m)
  },
  {
    name: preview_45popupJtWWWqggPJMeta?.name ?? "cms-preview-popup___de___default",
    path: preview_45popupJtWWWqggPJMeta?.path ?? "/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    alias: preview_45popupJtWWWqggPJMeta?.alias || [],
    redirect: preview_45popupJtWWWqggPJMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue").then(m => m.default || m)
  },
  {
    name: preview_45popupJtWWWqggPJMeta?.name ?? "cms-preview-popup___de",
    path: preview_45popupJtWWWqggPJMeta?.path ?? "/de/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    alias: preview_45popupJtWWWqggPJMeta?.alias || [],
    redirect: preview_45popupJtWWWqggPJMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue").then(m => m.default || m)
  },
  {
    name: preview_45popupJtWWWqggPJMeta?.name ?? "cms-preview-popup___pl",
    path: preview_45popupJtWWWqggPJMeta?.path ?? "/pl/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    alias: preview_45popupJtWWWqggPJMeta?.alias || [],
    redirect: preview_45popupJtWWWqggPJMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue").then(m => m.default || m)
  },
  {
    name: preview_45popupJtWWWqggPJMeta?.name ?? "cms-preview-popup___cs",
    path: preview_45popupJtWWWqggPJMeta?.path ?? "/cs/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    alias: preview_45popupJtWWWqggPJMeta?.alias || [],
    redirect: preview_45popupJtWWWqggPJMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue").then(m => m.default || m)
  },
  {
    name: preview_45popupJtWWWqggPJMeta?.name ?? "cms-preview-popup___et",
    path: preview_45popupJtWWWqggPJMeta?.path ?? "/et/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    alias: preview_45popupJtWWWqggPJMeta?.alias || [],
    redirect: preview_45popupJtWWWqggPJMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue").then(m => m.default || m)
  },
  {
    name: preview_45popupJtWWWqggPJMeta?.name ?? "cms-preview-popup___es",
    path: preview_45popupJtWWWqggPJMeta?.path ?? "/es/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    alias: preview_45popupJtWWWqggPJMeta?.alias || [],
    redirect: preview_45popupJtWWWqggPJMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue").then(m => m.default || m)
  },
  {
    name: preview_45popupJtWWWqggPJMeta?.name ?? "cms-preview-popup___fr",
    path: preview_45popupJtWWWqggPJMeta?.path ?? "/fr/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    alias: preview_45popupJtWWWqggPJMeta?.alias || [],
    redirect: preview_45popupJtWWWqggPJMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue").then(m => m.default || m)
  },
  {
    name: preview_45popupJtWWWqggPJMeta?.name ?? "cms-preview-popup___hr",
    path: preview_45popupJtWWWqggPJMeta?.path ?? "/hr/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    alias: preview_45popupJtWWWqggPJMeta?.alias || [],
    redirect: preview_45popupJtWWWqggPJMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue").then(m => m.default || m)
  },
  {
    name: preview_45popupJtWWWqggPJMeta?.name ?? "cms-preview-popup___it",
    path: preview_45popupJtWWWqggPJMeta?.path ?? "/it/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    alias: preview_45popupJtWWWqggPJMeta?.alias || [],
    redirect: preview_45popupJtWWWqggPJMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue").then(m => m.default || m)
  },
  {
    name: preview_45popupJtWWWqggPJMeta?.name ?? "cms-preview-popup___lv",
    path: preview_45popupJtWWWqggPJMeta?.path ?? "/lv/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    alias: preview_45popupJtWWWqggPJMeta?.alias || [],
    redirect: preview_45popupJtWWWqggPJMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue").then(m => m.default || m)
  },
  {
    name: preview_45popupJtWWWqggPJMeta?.name ?? "cms-preview-popup___lt",
    path: preview_45popupJtWWWqggPJMeta?.path ?? "/lt/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    alias: preview_45popupJtWWWqggPJMeta?.alias || [],
    redirect: preview_45popupJtWWWqggPJMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue").then(m => m.default || m)
  },
  {
    name: preview_45popupJtWWWqggPJMeta?.name ?? "cms-preview-popup___hu",
    path: preview_45popupJtWWWqggPJMeta?.path ?? "/hu/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    alias: preview_45popupJtWWWqggPJMeta?.alias || [],
    redirect: preview_45popupJtWWWqggPJMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue").then(m => m.default || m)
  },
  {
    name: preview_45popupJtWWWqggPJMeta?.name ?? "cms-preview-popup___nl",
    path: preview_45popupJtWWWqggPJMeta?.path ?? "/nl/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    alias: preview_45popupJtWWWqggPJMeta?.alias || [],
    redirect: preview_45popupJtWWWqggPJMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue").then(m => m.default || m)
  },
  {
    name: preview_45popupJtWWWqggPJMeta?.name ?? "cms-preview-popup___pt",
    path: preview_45popupJtWWWqggPJMeta?.path ?? "/pt/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    alias: preview_45popupJtWWWqggPJMeta?.alias || [],
    redirect: preview_45popupJtWWWqggPJMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue").then(m => m.default || m)
  },
  {
    name: preview_45popupJtWWWqggPJMeta?.name ?? "cms-preview-popup___ro",
    path: preview_45popupJtWWWqggPJMeta?.path ?? "/ro/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    alias: preview_45popupJtWWWqggPJMeta?.alias || [],
    redirect: preview_45popupJtWWWqggPJMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue").then(m => m.default || m)
  },
  {
    name: preview_45popupJtWWWqggPJMeta?.name ?? "cms-preview-popup___sk",
    path: preview_45popupJtWWWqggPJMeta?.path ?? "/sk/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    alias: preview_45popupJtWWWqggPJMeta?.alias || [],
    redirect: preview_45popupJtWWWqggPJMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue").then(m => m.default || m)
  },
  {
    name: preview_45popupJtWWWqggPJMeta?.name ?? "cms-preview-popup___sl",
    path: preview_45popupJtWWWqggPJMeta?.path ?? "/sl/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    alias: preview_45popupJtWWWqggPJMeta?.alias || [],
    redirect: preview_45popupJtWWWqggPJMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue").then(m => m.default || m)
  },
  {
    name: preview_45popupJtWWWqggPJMeta?.name ?? "cms-preview-popup___sr",
    path: preview_45popupJtWWWqggPJMeta?.path ?? "/sr/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    alias: preview_45popupJtWWWqggPJMeta?.alias || [],
    redirect: preview_45popupJtWWWqggPJMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue").then(m => m.default || m)
  },
  {
    name: preview_45popupJtWWWqggPJMeta?.name ?? "cms-preview-popup___tr",
    path: preview_45popupJtWWWqggPJMeta?.path ?? "/tr/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    alias: preview_45popupJtWWWqggPJMeta?.alias || [],
    redirect: preview_45popupJtWWWqggPJMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue").then(m => m.default || m)
  },
  {
    name: preview_45popupJtWWWqggPJMeta?.name ?? "cms-preview-popup___el",
    path: preview_45popupJtWWWqggPJMeta?.path ?? "/el/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    alias: preview_45popupJtWWWqggPJMeta?.alias || [],
    redirect: preview_45popupJtWWWqggPJMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue").then(m => m.default || m)
  },
  {
    name: preview_45popupJtWWWqggPJMeta?.name ?? "cms-preview-popup___bg",
    path: preview_45popupJtWWWqggPJMeta?.path ?? "/bg/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    alias: preview_45popupJtWWWqggPJMeta?.alias || [],
    redirect: preview_45popupJtWWWqggPJMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue").then(m => m.default || m)
  },
  {
    name: preview_45popupJtWWWqggPJMeta?.name ?? "cms-preview-popup___ru",
    path: preview_45popupJtWWWqggPJMeta?.path ?? "/ru/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    alias: preview_45popupJtWWWqggPJMeta?.alias || [],
    redirect: preview_45popupJtWWWqggPJMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue").then(m => m.default || m)
  },
  {
    name: previewePwT9PCja7Meta?.name ?? "cms-preview___en",
    path: previewePwT9PCja7Meta?.path ?? "/en/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    alias: previewePwT9PCja7Meta?.alias || [],
    redirect: previewePwT9PCja7Meta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewePwT9PCja7Meta?.name ?? "cms-preview___de___default",
    path: previewePwT9PCja7Meta?.path ?? "/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    alias: previewePwT9PCja7Meta?.alias || [],
    redirect: previewePwT9PCja7Meta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewePwT9PCja7Meta?.name ?? "cms-preview___de",
    path: previewePwT9PCja7Meta?.path ?? "/de/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    alias: previewePwT9PCja7Meta?.alias || [],
    redirect: previewePwT9PCja7Meta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewePwT9PCja7Meta?.name ?? "cms-preview___pl",
    path: previewePwT9PCja7Meta?.path ?? "/pl/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    alias: previewePwT9PCja7Meta?.alias || [],
    redirect: previewePwT9PCja7Meta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewePwT9PCja7Meta?.name ?? "cms-preview___cs",
    path: previewePwT9PCja7Meta?.path ?? "/cs/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    alias: previewePwT9PCja7Meta?.alias || [],
    redirect: previewePwT9PCja7Meta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewePwT9PCja7Meta?.name ?? "cms-preview___et",
    path: previewePwT9PCja7Meta?.path ?? "/et/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    alias: previewePwT9PCja7Meta?.alias || [],
    redirect: previewePwT9PCja7Meta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewePwT9PCja7Meta?.name ?? "cms-preview___es",
    path: previewePwT9PCja7Meta?.path ?? "/es/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    alias: previewePwT9PCja7Meta?.alias || [],
    redirect: previewePwT9PCja7Meta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewePwT9PCja7Meta?.name ?? "cms-preview___fr",
    path: previewePwT9PCja7Meta?.path ?? "/fr/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    alias: previewePwT9PCja7Meta?.alias || [],
    redirect: previewePwT9PCja7Meta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewePwT9PCja7Meta?.name ?? "cms-preview___hr",
    path: previewePwT9PCja7Meta?.path ?? "/hr/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    alias: previewePwT9PCja7Meta?.alias || [],
    redirect: previewePwT9PCja7Meta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewePwT9PCja7Meta?.name ?? "cms-preview___it",
    path: previewePwT9PCja7Meta?.path ?? "/it/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    alias: previewePwT9PCja7Meta?.alias || [],
    redirect: previewePwT9PCja7Meta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewePwT9PCja7Meta?.name ?? "cms-preview___lv",
    path: previewePwT9PCja7Meta?.path ?? "/lv/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    alias: previewePwT9PCja7Meta?.alias || [],
    redirect: previewePwT9PCja7Meta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewePwT9PCja7Meta?.name ?? "cms-preview___lt",
    path: previewePwT9PCja7Meta?.path ?? "/lt/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    alias: previewePwT9PCja7Meta?.alias || [],
    redirect: previewePwT9PCja7Meta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewePwT9PCja7Meta?.name ?? "cms-preview___hu",
    path: previewePwT9PCja7Meta?.path ?? "/hu/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    alias: previewePwT9PCja7Meta?.alias || [],
    redirect: previewePwT9PCja7Meta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewePwT9PCja7Meta?.name ?? "cms-preview___nl",
    path: previewePwT9PCja7Meta?.path ?? "/nl/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    alias: previewePwT9PCja7Meta?.alias || [],
    redirect: previewePwT9PCja7Meta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewePwT9PCja7Meta?.name ?? "cms-preview___pt",
    path: previewePwT9PCja7Meta?.path ?? "/pt/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    alias: previewePwT9PCja7Meta?.alias || [],
    redirect: previewePwT9PCja7Meta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewePwT9PCja7Meta?.name ?? "cms-preview___ro",
    path: previewePwT9PCja7Meta?.path ?? "/ro/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    alias: previewePwT9PCja7Meta?.alias || [],
    redirect: previewePwT9PCja7Meta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewePwT9PCja7Meta?.name ?? "cms-preview___sk",
    path: previewePwT9PCja7Meta?.path ?? "/sk/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    alias: previewePwT9PCja7Meta?.alias || [],
    redirect: previewePwT9PCja7Meta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewePwT9PCja7Meta?.name ?? "cms-preview___sl",
    path: previewePwT9PCja7Meta?.path ?? "/sl/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    alias: previewePwT9PCja7Meta?.alias || [],
    redirect: previewePwT9PCja7Meta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewePwT9PCja7Meta?.name ?? "cms-preview___sr",
    path: previewePwT9PCja7Meta?.path ?? "/sr/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    alias: previewePwT9PCja7Meta?.alias || [],
    redirect: previewePwT9PCja7Meta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewePwT9PCja7Meta?.name ?? "cms-preview___tr",
    path: previewePwT9PCja7Meta?.path ?? "/tr/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    alias: previewePwT9PCja7Meta?.alias || [],
    redirect: previewePwT9PCja7Meta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewePwT9PCja7Meta?.name ?? "cms-preview___el",
    path: previewePwT9PCja7Meta?.path ?? "/el/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    alias: previewePwT9PCja7Meta?.alias || [],
    redirect: previewePwT9PCja7Meta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewePwT9PCja7Meta?.name ?? "cms-preview___bg",
    path: previewePwT9PCja7Meta?.path ?? "/bg/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    alias: previewePwT9PCja7Meta?.alias || [],
    redirect: previewePwT9PCja7Meta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewePwT9PCja7Meta?.name ?? "cms-preview___ru",
    path: previewePwT9PCja7Meta?.path ?? "/ru/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    alias: previewePwT9PCja7Meta?.alias || [],
    redirect: previewePwT9PCja7Meta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: slideY9kgKluY4KMeta?.name ?? "cms-slide___en",
    path: slideY9kgKluY4KMeta?.path ?? "/en/cms/slide",
    meta: slideY9kgKluY4KMeta || {},
    alias: slideY9kgKluY4KMeta?.alias || [],
    redirect: slideY9kgKluY4KMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/slide.vue").then(m => m.default || m)
  },
  {
    name: slideY9kgKluY4KMeta?.name ?? "cms-slide___de___default",
    path: slideY9kgKluY4KMeta?.path ?? "/cms/slide",
    meta: slideY9kgKluY4KMeta || {},
    alias: slideY9kgKluY4KMeta?.alias || [],
    redirect: slideY9kgKluY4KMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/slide.vue").then(m => m.default || m)
  },
  {
    name: slideY9kgKluY4KMeta?.name ?? "cms-slide___de",
    path: slideY9kgKluY4KMeta?.path ?? "/de/cms/slide",
    meta: slideY9kgKluY4KMeta || {},
    alias: slideY9kgKluY4KMeta?.alias || [],
    redirect: slideY9kgKluY4KMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/slide.vue").then(m => m.default || m)
  },
  {
    name: slideY9kgKluY4KMeta?.name ?? "cms-slide___pl",
    path: slideY9kgKluY4KMeta?.path ?? "/pl/cms/slide",
    meta: slideY9kgKluY4KMeta || {},
    alias: slideY9kgKluY4KMeta?.alias || [],
    redirect: slideY9kgKluY4KMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/slide.vue").then(m => m.default || m)
  },
  {
    name: slideY9kgKluY4KMeta?.name ?? "cms-slide___cs",
    path: slideY9kgKluY4KMeta?.path ?? "/cs/cms/slide",
    meta: slideY9kgKluY4KMeta || {},
    alias: slideY9kgKluY4KMeta?.alias || [],
    redirect: slideY9kgKluY4KMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/slide.vue").then(m => m.default || m)
  },
  {
    name: slideY9kgKluY4KMeta?.name ?? "cms-slide___et",
    path: slideY9kgKluY4KMeta?.path ?? "/et/cms/slide",
    meta: slideY9kgKluY4KMeta || {},
    alias: slideY9kgKluY4KMeta?.alias || [],
    redirect: slideY9kgKluY4KMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/slide.vue").then(m => m.default || m)
  },
  {
    name: slideY9kgKluY4KMeta?.name ?? "cms-slide___es",
    path: slideY9kgKluY4KMeta?.path ?? "/es/cms/slide",
    meta: slideY9kgKluY4KMeta || {},
    alias: slideY9kgKluY4KMeta?.alias || [],
    redirect: slideY9kgKluY4KMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/slide.vue").then(m => m.default || m)
  },
  {
    name: slideY9kgKluY4KMeta?.name ?? "cms-slide___fr",
    path: slideY9kgKluY4KMeta?.path ?? "/fr/cms/slide",
    meta: slideY9kgKluY4KMeta || {},
    alias: slideY9kgKluY4KMeta?.alias || [],
    redirect: slideY9kgKluY4KMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/slide.vue").then(m => m.default || m)
  },
  {
    name: slideY9kgKluY4KMeta?.name ?? "cms-slide___hr",
    path: slideY9kgKluY4KMeta?.path ?? "/hr/cms/slide",
    meta: slideY9kgKluY4KMeta || {},
    alias: slideY9kgKluY4KMeta?.alias || [],
    redirect: slideY9kgKluY4KMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/slide.vue").then(m => m.default || m)
  },
  {
    name: slideY9kgKluY4KMeta?.name ?? "cms-slide___it",
    path: slideY9kgKluY4KMeta?.path ?? "/it/cms/slide",
    meta: slideY9kgKluY4KMeta || {},
    alias: slideY9kgKluY4KMeta?.alias || [],
    redirect: slideY9kgKluY4KMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/slide.vue").then(m => m.default || m)
  },
  {
    name: slideY9kgKluY4KMeta?.name ?? "cms-slide___lv",
    path: slideY9kgKluY4KMeta?.path ?? "/lv/cms/slide",
    meta: slideY9kgKluY4KMeta || {},
    alias: slideY9kgKluY4KMeta?.alias || [],
    redirect: slideY9kgKluY4KMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/slide.vue").then(m => m.default || m)
  },
  {
    name: slideY9kgKluY4KMeta?.name ?? "cms-slide___lt",
    path: slideY9kgKluY4KMeta?.path ?? "/lt/cms/slide",
    meta: slideY9kgKluY4KMeta || {},
    alias: slideY9kgKluY4KMeta?.alias || [],
    redirect: slideY9kgKluY4KMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/slide.vue").then(m => m.default || m)
  },
  {
    name: slideY9kgKluY4KMeta?.name ?? "cms-slide___hu",
    path: slideY9kgKluY4KMeta?.path ?? "/hu/cms/slide",
    meta: slideY9kgKluY4KMeta || {},
    alias: slideY9kgKluY4KMeta?.alias || [],
    redirect: slideY9kgKluY4KMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/slide.vue").then(m => m.default || m)
  },
  {
    name: slideY9kgKluY4KMeta?.name ?? "cms-slide___nl",
    path: slideY9kgKluY4KMeta?.path ?? "/nl/cms/slide",
    meta: slideY9kgKluY4KMeta || {},
    alias: slideY9kgKluY4KMeta?.alias || [],
    redirect: slideY9kgKluY4KMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/slide.vue").then(m => m.default || m)
  },
  {
    name: slideY9kgKluY4KMeta?.name ?? "cms-slide___pt",
    path: slideY9kgKluY4KMeta?.path ?? "/pt/cms/slide",
    meta: slideY9kgKluY4KMeta || {},
    alias: slideY9kgKluY4KMeta?.alias || [],
    redirect: slideY9kgKluY4KMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/slide.vue").then(m => m.default || m)
  },
  {
    name: slideY9kgKluY4KMeta?.name ?? "cms-slide___ro",
    path: slideY9kgKluY4KMeta?.path ?? "/ro/cms/slide",
    meta: slideY9kgKluY4KMeta || {},
    alias: slideY9kgKluY4KMeta?.alias || [],
    redirect: slideY9kgKluY4KMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/slide.vue").then(m => m.default || m)
  },
  {
    name: slideY9kgKluY4KMeta?.name ?? "cms-slide___sk",
    path: slideY9kgKluY4KMeta?.path ?? "/sk/cms/slide",
    meta: slideY9kgKluY4KMeta || {},
    alias: slideY9kgKluY4KMeta?.alias || [],
    redirect: slideY9kgKluY4KMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/slide.vue").then(m => m.default || m)
  },
  {
    name: slideY9kgKluY4KMeta?.name ?? "cms-slide___sl",
    path: slideY9kgKluY4KMeta?.path ?? "/sl/cms/slide",
    meta: slideY9kgKluY4KMeta || {},
    alias: slideY9kgKluY4KMeta?.alias || [],
    redirect: slideY9kgKluY4KMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/slide.vue").then(m => m.default || m)
  },
  {
    name: slideY9kgKluY4KMeta?.name ?? "cms-slide___sr",
    path: slideY9kgKluY4KMeta?.path ?? "/sr/cms/slide",
    meta: slideY9kgKluY4KMeta || {},
    alias: slideY9kgKluY4KMeta?.alias || [],
    redirect: slideY9kgKluY4KMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/slide.vue").then(m => m.default || m)
  },
  {
    name: slideY9kgKluY4KMeta?.name ?? "cms-slide___tr",
    path: slideY9kgKluY4KMeta?.path ?? "/tr/cms/slide",
    meta: slideY9kgKluY4KMeta || {},
    alias: slideY9kgKluY4KMeta?.alias || [],
    redirect: slideY9kgKluY4KMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/slide.vue").then(m => m.default || m)
  },
  {
    name: slideY9kgKluY4KMeta?.name ?? "cms-slide___el",
    path: slideY9kgKluY4KMeta?.path ?? "/el/cms/slide",
    meta: slideY9kgKluY4KMeta || {},
    alias: slideY9kgKluY4KMeta?.alias || [],
    redirect: slideY9kgKluY4KMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/slide.vue").then(m => m.default || m)
  },
  {
    name: slideY9kgKluY4KMeta?.name ?? "cms-slide___bg",
    path: slideY9kgKluY4KMeta?.path ?? "/bg/cms/slide",
    meta: slideY9kgKluY4KMeta || {},
    alias: slideY9kgKluY4KMeta?.alias || [],
    redirect: slideY9kgKluY4KMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/slide.vue").then(m => m.default || m)
  },
  {
    name: slideY9kgKluY4KMeta?.name ?? "cms-slide___ru",
    path: slideY9kgKluY4KMeta?.path ?? "/ru/cms/slide",
    meta: slideY9kgKluY4KMeta || {},
    alias: slideY9kgKluY4KMeta?.alias || [],
    redirect: slideY9kgKluY4KMeta?.redirect,
    component: () => import("/app/apps/premium/pages/cms/slide.vue").then(m => m.default || m)
  },
  {
    name: detailsL6oxkYvYFuMeta?.name ?? "item-slug-details___en",
    path: detailsL6oxkYvYFuMeta?.path ?? "/en/item/:slug()/details",
    meta: detailsL6oxkYvYFuMeta || {},
    alias: detailsL6oxkYvYFuMeta?.alias || [],
    redirect: detailsL6oxkYvYFuMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsL6oxkYvYFuMeta?.name ?? "item-slug-details___de___default",
    path: detailsL6oxkYvYFuMeta?.path ?? "/artikel/:slug()/einzelheiten",
    meta: detailsL6oxkYvYFuMeta || {},
    alias: detailsL6oxkYvYFuMeta?.alias || [],
    redirect: detailsL6oxkYvYFuMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsL6oxkYvYFuMeta?.name ?? "item-slug-details___de",
    path: detailsL6oxkYvYFuMeta?.path ?? "/de/artikel/:slug()/einzelheiten",
    meta: detailsL6oxkYvYFuMeta || {},
    alias: detailsL6oxkYvYFuMeta?.alias || [],
    redirect: detailsL6oxkYvYFuMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsL6oxkYvYFuMeta?.name ?? "item-slug-details___pl",
    path: detailsL6oxkYvYFuMeta?.path ?? "/pl/przedmiot/:slug()/detale",
    meta: detailsL6oxkYvYFuMeta || {},
    alias: detailsL6oxkYvYFuMeta?.alias || [],
    redirect: detailsL6oxkYvYFuMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsL6oxkYvYFuMeta?.name ?? "item-slug-details___cs",
    path: detailsL6oxkYvYFuMeta?.path ?? "/cs/polozka/:slug()/podrobnosti",
    meta: detailsL6oxkYvYFuMeta || {},
    alias: detailsL6oxkYvYFuMeta?.alias || [],
    redirect: detailsL6oxkYvYFuMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsL6oxkYvYFuMeta?.name ?? "item-slug-details___et",
    path: detailsL6oxkYvYFuMeta?.path ?? "/et/uksus/:slug()/uksikasjad",
    meta: detailsL6oxkYvYFuMeta || {},
    alias: detailsL6oxkYvYFuMeta?.alias || [],
    redirect: detailsL6oxkYvYFuMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsL6oxkYvYFuMeta?.name ?? "item-slug-details___es",
    path: detailsL6oxkYvYFuMeta?.path ?? "/es/articulo/:slug()/detalles",
    meta: detailsL6oxkYvYFuMeta || {},
    alias: detailsL6oxkYvYFuMeta?.alias || [],
    redirect: detailsL6oxkYvYFuMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsL6oxkYvYFuMeta?.name ?? "item-slug-details___fr",
    path: detailsL6oxkYvYFuMeta?.path ?? "/fr/article/:slug()/details",
    meta: detailsL6oxkYvYFuMeta || {},
    alias: detailsL6oxkYvYFuMeta?.alias || [],
    redirect: detailsL6oxkYvYFuMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsL6oxkYvYFuMeta?.name ?? "item-slug-details___hr",
    path: detailsL6oxkYvYFuMeta?.path ?? "/hr/artikal/:slug()/pojedinosti",
    meta: detailsL6oxkYvYFuMeta || {},
    alias: detailsL6oxkYvYFuMeta?.alias || [],
    redirect: detailsL6oxkYvYFuMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsL6oxkYvYFuMeta?.name ?? "item-slug-details___it",
    path: detailsL6oxkYvYFuMeta?.path ?? "/it/articolo/:slug()/dettagli",
    meta: detailsL6oxkYvYFuMeta || {},
    alias: detailsL6oxkYvYFuMeta?.alias || [],
    redirect: detailsL6oxkYvYFuMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsL6oxkYvYFuMeta?.name ?? "item-slug-details___lv",
    path: detailsL6oxkYvYFuMeta?.path ?? "/lv/lieta/:slug()/detalas",
    meta: detailsL6oxkYvYFuMeta || {},
    alias: detailsL6oxkYvYFuMeta?.alias || [],
    redirect: detailsL6oxkYvYFuMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsL6oxkYvYFuMeta?.name ?? "item-slug-details___lt",
    path: detailsL6oxkYvYFuMeta?.path ?? "/lt/daiktas/:slug()/detales",
    meta: detailsL6oxkYvYFuMeta || {},
    alias: detailsL6oxkYvYFuMeta?.alias || [],
    redirect: detailsL6oxkYvYFuMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsL6oxkYvYFuMeta?.name ?? "item-slug-details___hu",
    path: detailsL6oxkYvYFuMeta?.path ?? "/hu/tetel/:slug()/reszletek",
    meta: detailsL6oxkYvYFuMeta || {},
    alias: detailsL6oxkYvYFuMeta?.alias || [],
    redirect: detailsL6oxkYvYFuMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsL6oxkYvYFuMeta?.name ?? "item-slug-details___nl",
    path: detailsL6oxkYvYFuMeta?.path ?? "/nl/item/:slug()/details",
    meta: detailsL6oxkYvYFuMeta || {},
    alias: detailsL6oxkYvYFuMeta?.alias || [],
    redirect: detailsL6oxkYvYFuMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsL6oxkYvYFuMeta?.name ?? "item-slug-details___pt",
    path: detailsL6oxkYvYFuMeta?.path ?? "/pt/item/:slug()/detalhes",
    meta: detailsL6oxkYvYFuMeta || {},
    alias: detailsL6oxkYvYFuMeta?.alias || [],
    redirect: detailsL6oxkYvYFuMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsL6oxkYvYFuMeta?.name ?? "item-slug-details___ro",
    path: detailsL6oxkYvYFuMeta?.path ?? "/ro/articol/:slug()/detalii",
    meta: detailsL6oxkYvYFuMeta || {},
    alias: detailsL6oxkYvYFuMeta?.alias || [],
    redirect: detailsL6oxkYvYFuMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsL6oxkYvYFuMeta?.name ?? "item-slug-details___sk",
    path: detailsL6oxkYvYFuMeta?.path ?? "/sk/polozka/:slug()/podrobnosti",
    meta: detailsL6oxkYvYFuMeta || {},
    alias: detailsL6oxkYvYFuMeta?.alias || [],
    redirect: detailsL6oxkYvYFuMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsL6oxkYvYFuMeta?.name ?? "item-slug-details___sl",
    path: detailsL6oxkYvYFuMeta?.path ?? "/sl/postavka/:slug()/podrobnosti",
    meta: detailsL6oxkYvYFuMeta || {},
    alias: detailsL6oxkYvYFuMeta?.alias || [],
    redirect: detailsL6oxkYvYFuMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsL6oxkYvYFuMeta?.name ?? "item-slug-details___sr",
    path: detailsL6oxkYvYFuMeta?.path ?? "/sr/predmet/:slug()/detaljima",
    meta: detailsL6oxkYvYFuMeta || {},
    alias: detailsL6oxkYvYFuMeta?.alias || [],
    redirect: detailsL6oxkYvYFuMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsL6oxkYvYFuMeta?.name ?? "item-slug-details___tr",
    path: detailsL6oxkYvYFuMeta?.path ?? "/tr/oge/:slug()/detaylar",
    meta: detailsL6oxkYvYFuMeta || {},
    alias: detailsL6oxkYvYFuMeta?.alias || [],
    redirect: detailsL6oxkYvYFuMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsL6oxkYvYFuMeta?.name ?? "item-slug-details___el",
    path: detailsL6oxkYvYFuMeta?.path ?? "/el/eidos/:slug()/leptomeries",
    meta: detailsL6oxkYvYFuMeta || {},
    alias: detailsL6oxkYvYFuMeta?.alias || [],
    redirect: detailsL6oxkYvYFuMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsL6oxkYvYFuMeta?.name ?? "item-slug-details___bg",
    path: detailsL6oxkYvYFuMeta?.path ?? "/bg/vesh/:slug()/podrobnosti",
    meta: detailsL6oxkYvYFuMeta || {},
    alias: detailsL6oxkYvYFuMeta?.alias || [],
    redirect: detailsL6oxkYvYFuMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsL6oxkYvYFuMeta?.name ?? "item-slug-details___ru",
    path: detailsL6oxkYvYFuMeta?.path ?? "/ru/element/:slug()/podrobnosti",
    meta: detailsL6oxkYvYFuMeta || {},
    alias: detailsL6oxkYvYFuMeta?.alias || [],
    redirect: detailsL6oxkYvYFuMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: indexMvQlE1AIqkMeta?.name ?? "item-slug___en",
    path: indexMvQlE1AIqkMeta?.path ?? "/en/item/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    alias: indexMvQlE1AIqkMeta?.alias || [],
    redirect: indexMvQlE1AIqkMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMvQlE1AIqkMeta?.name ?? "item-slug___de___default",
    path: indexMvQlE1AIqkMeta?.path ?? "/artikel/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    alias: indexMvQlE1AIqkMeta?.alias || [],
    redirect: indexMvQlE1AIqkMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMvQlE1AIqkMeta?.name ?? "item-slug___de",
    path: indexMvQlE1AIqkMeta?.path ?? "/de/artikel/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    alias: indexMvQlE1AIqkMeta?.alias || [],
    redirect: indexMvQlE1AIqkMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMvQlE1AIqkMeta?.name ?? "item-slug___pl",
    path: indexMvQlE1AIqkMeta?.path ?? "/pl/przedmiot/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    alias: indexMvQlE1AIqkMeta?.alias || [],
    redirect: indexMvQlE1AIqkMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMvQlE1AIqkMeta?.name ?? "item-slug___cs",
    path: indexMvQlE1AIqkMeta?.path ?? "/cs/polozka/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    alias: indexMvQlE1AIqkMeta?.alias || [],
    redirect: indexMvQlE1AIqkMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMvQlE1AIqkMeta?.name ?? "item-slug___et",
    path: indexMvQlE1AIqkMeta?.path ?? "/et/uksus/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    alias: indexMvQlE1AIqkMeta?.alias || [],
    redirect: indexMvQlE1AIqkMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMvQlE1AIqkMeta?.name ?? "item-slug___es",
    path: indexMvQlE1AIqkMeta?.path ?? "/es/articulo/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    alias: indexMvQlE1AIqkMeta?.alias || [],
    redirect: indexMvQlE1AIqkMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMvQlE1AIqkMeta?.name ?? "item-slug___fr",
    path: indexMvQlE1AIqkMeta?.path ?? "/fr/article/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    alias: indexMvQlE1AIqkMeta?.alias || [],
    redirect: indexMvQlE1AIqkMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMvQlE1AIqkMeta?.name ?? "item-slug___hr",
    path: indexMvQlE1AIqkMeta?.path ?? "/hr/artikal/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    alias: indexMvQlE1AIqkMeta?.alias || [],
    redirect: indexMvQlE1AIqkMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMvQlE1AIqkMeta?.name ?? "item-slug___it",
    path: indexMvQlE1AIqkMeta?.path ?? "/it/articolo/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    alias: indexMvQlE1AIqkMeta?.alias || [],
    redirect: indexMvQlE1AIqkMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMvQlE1AIqkMeta?.name ?? "item-slug___lv",
    path: indexMvQlE1AIqkMeta?.path ?? "/lv/lieta/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    alias: indexMvQlE1AIqkMeta?.alias || [],
    redirect: indexMvQlE1AIqkMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMvQlE1AIqkMeta?.name ?? "item-slug___lt",
    path: indexMvQlE1AIqkMeta?.path ?? "/lt/daiktas/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    alias: indexMvQlE1AIqkMeta?.alias || [],
    redirect: indexMvQlE1AIqkMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMvQlE1AIqkMeta?.name ?? "item-slug___hu",
    path: indexMvQlE1AIqkMeta?.path ?? "/hu/tetel/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    alias: indexMvQlE1AIqkMeta?.alias || [],
    redirect: indexMvQlE1AIqkMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMvQlE1AIqkMeta?.name ?? "item-slug___nl",
    path: indexMvQlE1AIqkMeta?.path ?? "/nl/item/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    alias: indexMvQlE1AIqkMeta?.alias || [],
    redirect: indexMvQlE1AIqkMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMvQlE1AIqkMeta?.name ?? "item-slug___pt",
    path: indexMvQlE1AIqkMeta?.path ?? "/pt/item/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    alias: indexMvQlE1AIqkMeta?.alias || [],
    redirect: indexMvQlE1AIqkMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMvQlE1AIqkMeta?.name ?? "item-slug___ro",
    path: indexMvQlE1AIqkMeta?.path ?? "/ro/articol/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    alias: indexMvQlE1AIqkMeta?.alias || [],
    redirect: indexMvQlE1AIqkMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMvQlE1AIqkMeta?.name ?? "item-slug___sk",
    path: indexMvQlE1AIqkMeta?.path ?? "/sk/polozka/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    alias: indexMvQlE1AIqkMeta?.alias || [],
    redirect: indexMvQlE1AIqkMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMvQlE1AIqkMeta?.name ?? "item-slug___sl",
    path: indexMvQlE1AIqkMeta?.path ?? "/sl/postavka/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    alias: indexMvQlE1AIqkMeta?.alias || [],
    redirect: indexMvQlE1AIqkMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMvQlE1AIqkMeta?.name ?? "item-slug___sr",
    path: indexMvQlE1AIqkMeta?.path ?? "/sr/predmet/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    alias: indexMvQlE1AIqkMeta?.alias || [],
    redirect: indexMvQlE1AIqkMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMvQlE1AIqkMeta?.name ?? "item-slug___tr",
    path: indexMvQlE1AIqkMeta?.path ?? "/tr/oge/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    alias: indexMvQlE1AIqkMeta?.alias || [],
    redirect: indexMvQlE1AIqkMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMvQlE1AIqkMeta?.name ?? "item-slug___el",
    path: indexMvQlE1AIqkMeta?.path ?? "/el/eidos/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    alias: indexMvQlE1AIqkMeta?.alias || [],
    redirect: indexMvQlE1AIqkMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMvQlE1AIqkMeta?.name ?? "item-slug___bg",
    path: indexMvQlE1AIqkMeta?.path ?? "/bg/vesh/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    alias: indexMvQlE1AIqkMeta?.alias || [],
    redirect: indexMvQlE1AIqkMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMvQlE1AIqkMeta?.name ?? "item-slug___ru",
    path: indexMvQlE1AIqkMeta?.path ?? "/ru/element/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    alias: indexMvQlE1AIqkMeta?.alias || [],
    redirect: indexMvQlE1AIqkMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: previewS7PgymDCArMeta?.name ?? "item-slug-preview___en",
    path: previewS7PgymDCArMeta?.path ?? "/en/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    alias: previewS7PgymDCArMeta?.alias || [],
    redirect: previewS7PgymDCArMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewS7PgymDCArMeta?.name ?? "item-slug-preview___de___default",
    path: previewS7PgymDCArMeta?.path ?? "/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    alias: previewS7PgymDCArMeta?.alias || [],
    redirect: previewS7PgymDCArMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewS7PgymDCArMeta?.name ?? "item-slug-preview___de",
    path: previewS7PgymDCArMeta?.path ?? "/de/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    alias: previewS7PgymDCArMeta?.alias || [],
    redirect: previewS7PgymDCArMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewS7PgymDCArMeta?.name ?? "item-slug-preview___pl",
    path: previewS7PgymDCArMeta?.path ?? "/pl/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    alias: previewS7PgymDCArMeta?.alias || [],
    redirect: previewS7PgymDCArMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewS7PgymDCArMeta?.name ?? "item-slug-preview___cs",
    path: previewS7PgymDCArMeta?.path ?? "/cs/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    alias: previewS7PgymDCArMeta?.alias || [],
    redirect: previewS7PgymDCArMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewS7PgymDCArMeta?.name ?? "item-slug-preview___et",
    path: previewS7PgymDCArMeta?.path ?? "/et/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    alias: previewS7PgymDCArMeta?.alias || [],
    redirect: previewS7PgymDCArMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewS7PgymDCArMeta?.name ?? "item-slug-preview___es",
    path: previewS7PgymDCArMeta?.path ?? "/es/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    alias: previewS7PgymDCArMeta?.alias || [],
    redirect: previewS7PgymDCArMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewS7PgymDCArMeta?.name ?? "item-slug-preview___fr",
    path: previewS7PgymDCArMeta?.path ?? "/fr/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    alias: previewS7PgymDCArMeta?.alias || [],
    redirect: previewS7PgymDCArMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewS7PgymDCArMeta?.name ?? "item-slug-preview___hr",
    path: previewS7PgymDCArMeta?.path ?? "/hr/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    alias: previewS7PgymDCArMeta?.alias || [],
    redirect: previewS7PgymDCArMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewS7PgymDCArMeta?.name ?? "item-slug-preview___it",
    path: previewS7PgymDCArMeta?.path ?? "/it/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    alias: previewS7PgymDCArMeta?.alias || [],
    redirect: previewS7PgymDCArMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewS7PgymDCArMeta?.name ?? "item-slug-preview___lv",
    path: previewS7PgymDCArMeta?.path ?? "/lv/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    alias: previewS7PgymDCArMeta?.alias || [],
    redirect: previewS7PgymDCArMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewS7PgymDCArMeta?.name ?? "item-slug-preview___lt",
    path: previewS7PgymDCArMeta?.path ?? "/lt/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    alias: previewS7PgymDCArMeta?.alias || [],
    redirect: previewS7PgymDCArMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewS7PgymDCArMeta?.name ?? "item-slug-preview___hu",
    path: previewS7PgymDCArMeta?.path ?? "/hu/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    alias: previewS7PgymDCArMeta?.alias || [],
    redirect: previewS7PgymDCArMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewS7PgymDCArMeta?.name ?? "item-slug-preview___nl",
    path: previewS7PgymDCArMeta?.path ?? "/nl/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    alias: previewS7PgymDCArMeta?.alias || [],
    redirect: previewS7PgymDCArMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewS7PgymDCArMeta?.name ?? "item-slug-preview___pt",
    path: previewS7PgymDCArMeta?.path ?? "/pt/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    alias: previewS7PgymDCArMeta?.alias || [],
    redirect: previewS7PgymDCArMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewS7PgymDCArMeta?.name ?? "item-slug-preview___ro",
    path: previewS7PgymDCArMeta?.path ?? "/ro/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    alias: previewS7PgymDCArMeta?.alias || [],
    redirect: previewS7PgymDCArMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewS7PgymDCArMeta?.name ?? "item-slug-preview___sk",
    path: previewS7PgymDCArMeta?.path ?? "/sk/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    alias: previewS7PgymDCArMeta?.alias || [],
    redirect: previewS7PgymDCArMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewS7PgymDCArMeta?.name ?? "item-slug-preview___sl",
    path: previewS7PgymDCArMeta?.path ?? "/sl/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    alias: previewS7PgymDCArMeta?.alias || [],
    redirect: previewS7PgymDCArMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewS7PgymDCArMeta?.name ?? "item-slug-preview___sr",
    path: previewS7PgymDCArMeta?.path ?? "/sr/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    alias: previewS7PgymDCArMeta?.alias || [],
    redirect: previewS7PgymDCArMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewS7PgymDCArMeta?.name ?? "item-slug-preview___tr",
    path: previewS7PgymDCArMeta?.path ?? "/tr/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    alias: previewS7PgymDCArMeta?.alias || [],
    redirect: previewS7PgymDCArMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewS7PgymDCArMeta?.name ?? "item-slug-preview___el",
    path: previewS7PgymDCArMeta?.path ?? "/el/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    alias: previewS7PgymDCArMeta?.alias || [],
    redirect: previewS7PgymDCArMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewS7PgymDCArMeta?.name ?? "item-slug-preview___bg",
    path: previewS7PgymDCArMeta?.path ?? "/bg/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    alias: previewS7PgymDCArMeta?.alias || [],
    redirect: previewS7PgymDCArMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewS7PgymDCArMeta?.name ?? "item-slug-preview___ru",
    path: previewS7PgymDCArMeta?.path ?? "/ru/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    alias: previewS7PgymDCArMeta?.alias || [],
    redirect: previewS7PgymDCArMeta?.redirect,
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: loginVvBJLg8r8VMeta?.name ?? "login___en",
    path: loginVvBJLg8r8VMeta?.path ?? "/en/login",
    meta: loginVvBJLg8r8VMeta || {},
    alias: loginVvBJLg8r8VMeta?.alias || [],
    redirect: loginVvBJLg8r8VMeta?.redirect,
    component: () => import("/app/apps/premium/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginVvBJLg8r8VMeta?.name ?? "login___de___default",
    path: loginVvBJLg8r8VMeta?.path ?? "/login",
    meta: loginVvBJLg8r8VMeta || {},
    alias: loginVvBJLg8r8VMeta?.alias || [],
    redirect: loginVvBJLg8r8VMeta?.redirect,
    component: () => import("/app/apps/premium/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginVvBJLg8r8VMeta?.name ?? "login___de",
    path: loginVvBJLg8r8VMeta?.path ?? "/de/login",
    meta: loginVvBJLg8r8VMeta || {},
    alias: loginVvBJLg8r8VMeta?.alias || [],
    redirect: loginVvBJLg8r8VMeta?.redirect,
    component: () => import("/app/apps/premium/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginVvBJLg8r8VMeta?.name ?? "login___pl",
    path: loginVvBJLg8r8VMeta?.path ?? "/pl/login",
    meta: loginVvBJLg8r8VMeta || {},
    alias: loginVvBJLg8r8VMeta?.alias || [],
    redirect: loginVvBJLg8r8VMeta?.redirect,
    component: () => import("/app/apps/premium/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginVvBJLg8r8VMeta?.name ?? "login___cs",
    path: loginVvBJLg8r8VMeta?.path ?? "/cs/login",
    meta: loginVvBJLg8r8VMeta || {},
    alias: loginVvBJLg8r8VMeta?.alias || [],
    redirect: loginVvBJLg8r8VMeta?.redirect,
    component: () => import("/app/apps/premium/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginVvBJLg8r8VMeta?.name ?? "login___et",
    path: loginVvBJLg8r8VMeta?.path ?? "/et/login",
    meta: loginVvBJLg8r8VMeta || {},
    alias: loginVvBJLg8r8VMeta?.alias || [],
    redirect: loginVvBJLg8r8VMeta?.redirect,
    component: () => import("/app/apps/premium/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginVvBJLg8r8VMeta?.name ?? "login___es",
    path: loginVvBJLg8r8VMeta?.path ?? "/es/login",
    meta: loginVvBJLg8r8VMeta || {},
    alias: loginVvBJLg8r8VMeta?.alias || [],
    redirect: loginVvBJLg8r8VMeta?.redirect,
    component: () => import("/app/apps/premium/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginVvBJLg8r8VMeta?.name ?? "login___fr",
    path: loginVvBJLg8r8VMeta?.path ?? "/fr/login",
    meta: loginVvBJLg8r8VMeta || {},
    alias: loginVvBJLg8r8VMeta?.alias || [],
    redirect: loginVvBJLg8r8VMeta?.redirect,
    component: () => import("/app/apps/premium/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginVvBJLg8r8VMeta?.name ?? "login___hr",
    path: loginVvBJLg8r8VMeta?.path ?? "/hr/login",
    meta: loginVvBJLg8r8VMeta || {},
    alias: loginVvBJLg8r8VMeta?.alias || [],
    redirect: loginVvBJLg8r8VMeta?.redirect,
    component: () => import("/app/apps/premium/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginVvBJLg8r8VMeta?.name ?? "login___it",
    path: loginVvBJLg8r8VMeta?.path ?? "/it/login",
    meta: loginVvBJLg8r8VMeta || {},
    alias: loginVvBJLg8r8VMeta?.alias || [],
    redirect: loginVvBJLg8r8VMeta?.redirect,
    component: () => import("/app/apps/premium/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginVvBJLg8r8VMeta?.name ?? "login___lv",
    path: loginVvBJLg8r8VMeta?.path ?? "/lv/login",
    meta: loginVvBJLg8r8VMeta || {},
    alias: loginVvBJLg8r8VMeta?.alias || [],
    redirect: loginVvBJLg8r8VMeta?.redirect,
    component: () => import("/app/apps/premium/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginVvBJLg8r8VMeta?.name ?? "login___lt",
    path: loginVvBJLg8r8VMeta?.path ?? "/lt/login",
    meta: loginVvBJLg8r8VMeta || {},
    alias: loginVvBJLg8r8VMeta?.alias || [],
    redirect: loginVvBJLg8r8VMeta?.redirect,
    component: () => import("/app/apps/premium/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginVvBJLg8r8VMeta?.name ?? "login___hu",
    path: loginVvBJLg8r8VMeta?.path ?? "/hu/login",
    meta: loginVvBJLg8r8VMeta || {},
    alias: loginVvBJLg8r8VMeta?.alias || [],
    redirect: loginVvBJLg8r8VMeta?.redirect,
    component: () => import("/app/apps/premium/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginVvBJLg8r8VMeta?.name ?? "login___nl",
    path: loginVvBJLg8r8VMeta?.path ?? "/nl/login",
    meta: loginVvBJLg8r8VMeta || {},
    alias: loginVvBJLg8r8VMeta?.alias || [],
    redirect: loginVvBJLg8r8VMeta?.redirect,
    component: () => import("/app/apps/premium/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginVvBJLg8r8VMeta?.name ?? "login___pt",
    path: loginVvBJLg8r8VMeta?.path ?? "/pt/login",
    meta: loginVvBJLg8r8VMeta || {},
    alias: loginVvBJLg8r8VMeta?.alias || [],
    redirect: loginVvBJLg8r8VMeta?.redirect,
    component: () => import("/app/apps/premium/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginVvBJLg8r8VMeta?.name ?? "login___ro",
    path: loginVvBJLg8r8VMeta?.path ?? "/ro/login",
    meta: loginVvBJLg8r8VMeta || {},
    alias: loginVvBJLg8r8VMeta?.alias || [],
    redirect: loginVvBJLg8r8VMeta?.redirect,
    component: () => import("/app/apps/premium/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginVvBJLg8r8VMeta?.name ?? "login___sk",
    path: loginVvBJLg8r8VMeta?.path ?? "/sk/login",
    meta: loginVvBJLg8r8VMeta || {},
    alias: loginVvBJLg8r8VMeta?.alias || [],
    redirect: loginVvBJLg8r8VMeta?.redirect,
    component: () => import("/app/apps/premium/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginVvBJLg8r8VMeta?.name ?? "login___sl",
    path: loginVvBJLg8r8VMeta?.path ?? "/sl/login",
    meta: loginVvBJLg8r8VMeta || {},
    alias: loginVvBJLg8r8VMeta?.alias || [],
    redirect: loginVvBJLg8r8VMeta?.redirect,
    component: () => import("/app/apps/premium/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginVvBJLg8r8VMeta?.name ?? "login___sr",
    path: loginVvBJLg8r8VMeta?.path ?? "/sr/login",
    meta: loginVvBJLg8r8VMeta || {},
    alias: loginVvBJLg8r8VMeta?.alias || [],
    redirect: loginVvBJLg8r8VMeta?.redirect,
    component: () => import("/app/apps/premium/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginVvBJLg8r8VMeta?.name ?? "login___tr",
    path: loginVvBJLg8r8VMeta?.path ?? "/tr/login",
    meta: loginVvBJLg8r8VMeta || {},
    alias: loginVvBJLg8r8VMeta?.alias || [],
    redirect: loginVvBJLg8r8VMeta?.redirect,
    component: () => import("/app/apps/premium/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginVvBJLg8r8VMeta?.name ?? "login___el",
    path: loginVvBJLg8r8VMeta?.path ?? "/el/login",
    meta: loginVvBJLg8r8VMeta || {},
    alias: loginVvBJLg8r8VMeta?.alias || [],
    redirect: loginVvBJLg8r8VMeta?.redirect,
    component: () => import("/app/apps/premium/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginVvBJLg8r8VMeta?.name ?? "login___bg",
    path: loginVvBJLg8r8VMeta?.path ?? "/bg/login",
    meta: loginVvBJLg8r8VMeta || {},
    alias: loginVvBJLg8r8VMeta?.alias || [],
    redirect: loginVvBJLg8r8VMeta?.redirect,
    component: () => import("/app/apps/premium/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginVvBJLg8r8VMeta?.name ?? "login___ru",
    path: loginVvBJLg8r8VMeta?.path ?? "/ru/login",
    meta: loginVvBJLg8r8VMeta || {},
    alias: loginVvBJLg8r8VMeta?.alias || [],
    redirect: loginVvBJLg8r8VMeta?.redirect,
    component: () => import("/app/apps/premium/pages/login.vue").then(m => m.default || m)
  }
]