
// @ts-nocheck


export const localeCodes =  [
  "en",
  "de",
  "pl",
  "cs",
  "et",
  "es",
  "fr",
  "hr",
  "it",
  "lv",
  "lt",
  "hu",
  "nl",
  "pt",
  "ro",
  "sk",
  "sl",
  "sr",
  "tr",
  "el",
  "bg",
  "ru"
]

export const localeLoaders = {
  "en": [{ key: "../i18n/en.json", load: () => import("../i18n/en.json" /* webpackChunkName: "locale__app_apps_premium_i18n_en_json" */), cache: true }],
  "de": [{ key: "../i18n/de.json", load: () => import("../i18n/de.json" /* webpackChunkName: "locale__app_apps_premium_i18n_de_json" */), cache: true }],
  "pl": [{ key: "../i18n/pl.json", load: () => import("../i18n/pl.json" /* webpackChunkName: "locale__app_apps_premium_i18n_pl_json" */), cache: true }],
  "cs": [{ key: "../i18n/cs.json", load: () => import("../i18n/cs.json" /* webpackChunkName: "locale__app_apps_premium_i18n_cs_json" */), cache: true }],
  "et": [{ key: "../i18n/et.json", load: () => import("../i18n/et.json" /* webpackChunkName: "locale__app_apps_premium_i18n_et_json" */), cache: true }],
  "es": [{ key: "../i18n/es.json", load: () => import("../i18n/es.json" /* webpackChunkName: "locale__app_apps_premium_i18n_es_json" */), cache: true }],
  "fr": [{ key: "../i18n/fr.json", load: () => import("../i18n/fr.json" /* webpackChunkName: "locale__app_apps_premium_i18n_fr_json" */), cache: true }],
  "hr": [{ key: "../i18n/hr.json", load: () => import("../i18n/hr.json" /* webpackChunkName: "locale__app_apps_premium_i18n_hr_json" */), cache: true }],
  "it": [{ key: "../i18n/it.json", load: () => import("../i18n/it.json" /* webpackChunkName: "locale__app_apps_premium_i18n_it_json" */), cache: true }],
  "lv": [{ key: "../i18n/lv.json", load: () => import("../i18n/lv.json" /* webpackChunkName: "locale__app_apps_premium_i18n_lv_json" */), cache: true }],
  "lt": [{ key: "../i18n/lt.json", load: () => import("../i18n/lt.json" /* webpackChunkName: "locale__app_apps_premium_i18n_lt_json" */), cache: true }],
  "hu": [{ key: "../i18n/hu.json", load: () => import("../i18n/hu.json" /* webpackChunkName: "locale__app_apps_premium_i18n_hu_json" */), cache: true }],
  "nl": [{ key: "../i18n/nl.json", load: () => import("../i18n/nl.json" /* webpackChunkName: "locale__app_apps_premium_i18n_nl_json" */), cache: true }],
  "pt": [{ key: "../i18n/pt.json", load: () => import("../i18n/pt.json" /* webpackChunkName: "locale__app_apps_premium_i18n_pt_json" */), cache: true }],
  "ro": [{ key: "../i18n/ro.json", load: () => import("../i18n/ro.json" /* webpackChunkName: "locale__app_apps_premium_i18n_ro_json" */), cache: true }],
  "sk": [{ key: "../i18n/sk.json", load: () => import("../i18n/sk.json" /* webpackChunkName: "locale__app_apps_premium_i18n_sk_json" */), cache: true }],
  "sl": [{ key: "../i18n/sl.json", load: () => import("../i18n/sl.json" /* webpackChunkName: "locale__app_apps_premium_i18n_sl_json" */), cache: true }],
  "sr": [{ key: "../i18n/sr.json", load: () => import("../i18n/sr.json" /* webpackChunkName: "locale__app_apps_premium_i18n_sr_json" */), cache: true }],
  "tr": [{ key: "../i18n/tr.json", load: () => import("../i18n/tr.json" /* webpackChunkName: "locale__app_apps_premium_i18n_tr_json" */), cache: true }],
  "el": [{ key: "../i18n/el.json", load: () => import("../i18n/el.json" /* webpackChunkName: "locale__app_apps_premium_i18n_el_json" */), cache: true }],
  "bg": [{ key: "../i18n/bg.json", load: () => import("../i18n/bg.json" /* webpackChunkName: "locale__app_apps_premium_i18n_bg_json" */), cache: true }],
  "ru": [{ key: "../i18n/ru.json", load: () => import("../i18n/ru.json" /* webpackChunkName: "locale__app_apps_premium_i18n_ru_json" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "premiumId": 1,
      "files": [
        "i18n/en.json"
      ]
    },
    {
      "code": "de",
      "premiumId": 0,
      "files": [
        "i18n/de.json"
      ]
    },
    {
      "code": "pl",
      "premiumId": 14,
      "files": [
        "i18n/pl.json"
      ]
    },
    {
      "code": "cs",
      "premiumId": 5,
      "files": [
        "i18n/cs.json"
      ]
    },
    {
      "code": "et",
      "premiumId": 6,
      "files": [
        "i18n/et.json"
      ]
    },
    {
      "code": "es",
      "premiumId": 7,
      "files": [
        "i18n/es.json"
      ]
    },
    {
      "code": "fr",
      "premiumId": 2,
      "files": [
        "i18n/fr.json"
      ]
    },
    {
      "code": "hr",
      "premiumId": 8,
      "files": [
        "i18n/hr.json"
      ]
    },
    {
      "code": "it",
      "premiumId": 9,
      "files": [
        "i18n/it.json"
      ]
    },
    {
      "code": "lv",
      "premiumId": 10,
      "files": [
        "i18n/lv.json"
      ]
    },
    {
      "code": "lt",
      "premiumId": 11,
      "files": [
        "i18n/lt.json"
      ]
    },
    {
      "code": "hu",
      "premiumId": 12,
      "files": [
        "i18n/hu.json"
      ]
    },
    {
      "code": "nl",
      "premiumId": 13,
      "files": [
        "i18n/nl.json"
      ]
    },
    {
      "code": "pt",
      "premiumId": 4,
      "files": [
        "i18n/pt.json"
      ]
    },
    {
      "code": "ro",
      "premiumId": 15,
      "files": [
        "i18n/ro.json"
      ]
    },
    {
      "code": "sk",
      "premiumId": 16,
      "files": [
        "i18n/sk.json"
      ]
    },
    {
      "code": "sl",
      "premiumId": 17,
      "files": [
        "i18n/sl.json"
      ]
    },
    {
      "code": "sr",
      "premiumId": 23,
      "files": [
        "i18n/sr.json"
      ]
    },
    {
      "code": "tr",
      "premiumId": 18,
      "files": [
        "i18n/tr.json"
      ]
    },
    {
      "code": "el",
      "premiumId": 3,
      "files": [
        "i18n/el.json"
      ]
    },
    {
      "code": "bg",
      "premiumId": 19,
      "files": [
        "i18n/bg.json"
      ]
    },
    {
      "code": "ru",
      "premiumId": 20,
      "files": [
        "i18n/ru.json"
      ]
    }
  ],
  "defaultLocale": "de",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_and_default",
  "lazy": true,
  "langDir": "i18n",
  "detectBrowserLanguage": {
    "alwaysRedirect": true,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "auction/[slug]": {
      "bg": "/turg/[slug]",
      "cs": "/aukce/[slug]",
      "de": "/auktion/[slug]",
      "el": "/dhmoprasia/[slug]",
      "en": "/auction/[slug]",
      "es": "/subasta/[slug]",
      "et": "/oksjon/[slug]",
      "fr": "/encheres/[slug]",
      "hr": "/aukcija/[slug]",
      "hu": "/arveres/[slug]",
      "it": "/asta/[slug]",
      "lt": "/aukcione/[slug]",
      "lv": "/izsole/[slug]",
      "nl": "/veiling/[slug]",
      "pl": "/aukcja/[slug]",
      "pt": "/leilao/[slug]",
      "ro": "/licitatie/[slug]",
      "ru": "/aukcion/[slug]",
      "sk": "/aukcie/[slug]",
      "sl": "/drazba/[slug]",
      "sr": "/aukcija/[slug]",
      "tr": "/acik-arttirma/[slug]"
    },
    "auction/current/[slug]": {
      "bg": "/turg/tekush/[slug]",
      "cs": "/aukce/aktualni/[slug]",
      "de": "/auktion/aktuell/[slug]",
      "el": "/dhmoprasia/reyma/[slug]",
      "en": "/auction/current/[slug]",
      "es": "/subasta/actual/[slug]",
      "et": "/oksjon/praegune/[slug]",
      "fr": "/encheres/actuel/[slug]",
      "hr": "/aukcija/trenutno/[slug]",
      "hu": "/arveres/jelenlegi/[slug]",
      "it": "/asta/attuale/[slug]",
      "lt": "/aukcione/srove/[slug]",
      "lv": "/izsole/strava/[slug]",
      "nl": "/veiling/huidig/[slug]",
      "pl": "/aukcja/aktualna/[slug]",
      "pt": "/leilao/atual/[slug]",
      "ro": "/licitatie/actual/[slug]",
      "ru": "/aukcion/tekushij/[slug]",
      "sk": "/aukcie/prud/[slug]",
      "sl": "/drazba/trenutno/[slug]",
      "sr": "/aukcija/trenutni/[slug]",
      "tr": "/acik-arttirma/akim/[slug]"
    },
    "item/[slug]/index": {
      "bg": "/vesh/[slug]",
      "cs": "/polozka/[slug]",
      "de": "/artikel/[slug]",
      "el": "/eidos/[slug]",
      "en": "/item/[slug]",
      "es": "/articulo/[slug]",
      "et": "/uksus/[slug]",
      "fr": "/article/[slug]",
      "hr": "/artikal/[slug]",
      "hu": "/tetel/[slug]",
      "it": "/articolo/[slug]",
      "lt": "/daiktas/[slug]",
      "lv": "/lieta/[slug]",
      "nl": "/item/[slug]",
      "pl": "/przedmiot/[slug]",
      "pt": "/item/[slug]",
      "ro": "/articol/[slug]",
      "ru": "/element/[slug]",
      "sk": "/polozka/[slug]",
      "sl": "/postavka/[slug]",
      "sr": "/predmet/[slug]",
      "tr": "/oge/[slug]"
    },
    "item/[slug]/details": {
      "bg": "/vesh/[slug]/podrobnosti",
      "cs": "/polozka/[slug]/podrobnosti",
      "de": "/artikel/[slug]/einzelheiten",
      "el": "/eidos/[slug]/leptomeries",
      "en": "/item/[slug]/details",
      "es": "/articulo/[slug]/detalles",
      "et": "/uksus/[slug]/uksikasjad",
      "fr": "/article/[slug]/details",
      "hr": "/artikal/[slug]/pojedinosti",
      "hu": "/tetel/[slug]/reszletek",
      "it": "/articolo/[slug]/dettagli",
      "lt": "/daiktas/[slug]/detales",
      "lv": "/lieta/[slug]/detalas",
      "nl": "/item/[slug]/details",
      "pl": "/przedmiot/[slug]/detale",
      "pt": "/item/[slug]/detalhes",
      "ro": "/articol/[slug]/detalii",
      "ru": "/element/[slug]/podrobnosti",
      "sk": "/polozka/[slug]/podrobnosti",
      "sl": "/postavka/[slug]/podrobnosti",
      "sr": "/predmet/[slug]/detaljima",
      "tr": "/oge/[slug]/detaylar"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "premiumId": 1,
    "files": [
      {
        "path": "i18n/en.json"
      }
    ]
  },
  {
    "code": "de",
    "premiumId": 0,
    "files": [
      {
        "path": "i18n/de.json"
      }
    ]
  },
  {
    "code": "pl",
    "premiumId": 14,
    "files": [
      {
        "path": "i18n/pl.json"
      }
    ]
  },
  {
    "code": "cs",
    "premiumId": 5,
    "files": [
      {
        "path": "i18n/cs.json"
      }
    ]
  },
  {
    "code": "et",
    "premiumId": 6,
    "files": [
      {
        "path": "i18n/et.json"
      }
    ]
  },
  {
    "code": "es",
    "premiumId": 7,
    "files": [
      {
        "path": "i18n/es.json"
      }
    ]
  },
  {
    "code": "fr",
    "premiumId": 2,
    "files": [
      {
        "path": "i18n/fr.json"
      }
    ]
  },
  {
    "code": "hr",
    "premiumId": 8,
    "files": [
      {
        "path": "i18n/hr.json"
      }
    ]
  },
  {
    "code": "it",
    "premiumId": 9,
    "files": [
      {
        "path": "i18n/it.json"
      }
    ]
  },
  {
    "code": "lv",
    "premiumId": 10,
    "files": [
      {
        "path": "i18n/lv.json"
      }
    ]
  },
  {
    "code": "lt",
    "premiumId": 11,
    "files": [
      {
        "path": "i18n/lt.json"
      }
    ]
  },
  {
    "code": "hu",
    "premiumId": 12,
    "files": [
      {
        "path": "i18n/hu.json"
      }
    ]
  },
  {
    "code": "nl",
    "premiumId": 13,
    "files": [
      {
        "path": "i18n/nl.json"
      }
    ]
  },
  {
    "code": "pt",
    "premiumId": 4,
    "files": [
      {
        "path": "i18n/pt.json"
      }
    ]
  },
  {
    "code": "ro",
    "premiumId": 15,
    "files": [
      {
        "path": "i18n/ro.json"
      }
    ]
  },
  {
    "code": "sk",
    "premiumId": 16,
    "files": [
      {
        "path": "i18n/sk.json"
      }
    ]
  },
  {
    "code": "sl",
    "premiumId": 17,
    "files": [
      {
        "path": "i18n/sl.json"
      }
    ]
  },
  {
    "code": "sr",
    "premiumId": 23,
    "files": [
      {
        "path": "i18n/sr.json"
      }
    ]
  },
  {
    "code": "tr",
    "premiumId": 18,
    "files": [
      {
        "path": "i18n/tr.json"
      }
    ]
  },
  {
    "code": "el",
    "premiumId": 3,
    "files": [
      {
        "path": "i18n/el.json"
      }
    ]
  },
  {
    "code": "bg",
    "premiumId": 19,
    "files": [
      {
        "path": "i18n/bg.json"
      }
    ]
  },
  {
    "code": "ru",
    "premiumId": 20,
    "files": [
      {
        "path": "i18n/ru.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
